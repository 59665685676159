import * as actionTypes from "../actionTypes";

export const timeReportsRetrieveTimeReports = () => ({
  type: actionTypes.TIME_REPORTS_RETRIEVE_TIME_REPORTS,
  payload: {}
});

export const timeReportsSetTimeReports = timeReports => ({
  type: actionTypes.TIME_REPORTS_SET_TIME_REPORTS,
  payload: { timeReports }
});

export const timeReportsSetParams = params => ({
  type: actionTypes.TIME_REPORTS_SET_PARAMS,
  payload: { params }
});

export const timeReportsSetFilters = filters => ({
  type: actionTypes.TIME_REPORTS_SET_FILTERS,
  payload: { filters }
});

export const timeReportsSetSorting = sorting => ({
  type: actionTypes.TIME_REPORTS_SET_SORTING,
  payload: { sorting }
});

export const timeReportsSetPager = pager => ({
  type: actionTypes.TIME_REPORTS_SET_PAGER,
  payload: { pager }
});

export const timeReportsResetParams = params => ({
  type: actionTypes.TIME_REPORTS_RESET_PARAMS,
  payload: { params }
});

export const timeReportsResetFilters = filters => ({
  type: actionTypes.TIME_REPORTS_RESET_FILTERS,
  payload: { filters }
});

export const timeReportsResetSorting = sorting => ({
  type: actionTypes.TIME_REPORTS_RESET_SORTING,
  payload: { sorting }
});

export const timeReportsResetPager = () => ({
  type: actionTypes.TIME_REPORTS_RESET_PAGER,
  payload: {}
});
