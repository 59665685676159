import {
  PROJECTS_RETRIEVE_PROJECTS,
  PROJECTS_SET_PROJECTS,
  PROJECTS_SET_PARAMS,
  PROJECTS_SET_SORTING,
  PROJECTS_SET_FILTERS,
  PROJECTS_SET_GROUPING,
  PROJECTS_RESET_PARAMS,
  PROJECTS_RESET_SORTING,
  PROJECTS_RESET_FILTERS,
  PROJECTS_RESET_GROUPING
} from "../actionTypes";

const initialState = {
  list: [],
  fetching: false,
  params: {
    accountManagerId: 0
  },
  filters: [{ columnName: "status", value: "active" }],
  sorting: [],
  grouping: [],
  inactive: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case PROJECTS_SET_PROJECTS:
      return { ...state, list: [...action.payload.projects], fetching: false };
    case PROJECTS_RETRIEVE_PROJECTS:
      return { ...state, fetching: true };
    case PROJECTS_SET_PARAMS:
      return { ...state, params: { ...action.payload.params } };
    case PROJECTS_SET_FILTERS:
      return { ...state, filters: [...action.payload.filters] };
    case PROJECTS_SET_SORTING:
      return { ...state, sorting: [...action.payload.sorting] };
    case PROJECTS_SET_GROUPING:
      return { ...state, grouping: [...action.payload.grouping] };
    case PROJECTS_RESET_PARAMS:
      return { ...state, params: { ...initialState.params } };
    case PROJECTS_RESET_FILTERS:
      return { ...state, filters: [...initialState.filters] };
    case PROJECTS_RESET_SORTING:
      return { ...state, sorting: [...initialState.sorting] };
    case PROJECTS_RESET_GROUPING:
      return { ...state, grouping: [...initialState.grouping] };
    default:
      return state;
  }
}
