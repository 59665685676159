import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ProjectGroup } from "../../types/projectGroup";
import { projectGroupsRetrieveGroups } from "../../redux/actions";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { Box, CircularProgress, Fab } from "@material-ui/core";
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import { makeStyles } from "@material-ui/styles";
import ProjectGroupsGrid from "../../components/ProjectGroups/ProjectGroupsGrid";
import { useIsContractOperationsAllowed } from "../../hooks";
import AddIcon from "@material-ui/icons/Add";
import { Link } from "react-router-dom";

// @ts-ignore
const useStyles = makeStyles(dashboardStyle);

const ProjectGroups = () => {
  const dispatch = useDispatch();

  const projectGroups: ProjectGroup[] = useSelector(
    // @ts-ignore
    state => state.projectGroups.data
  );
  const fetching: boolean = useSelector(
    // @ts-ignore
    state => state.projectGroups.fetching
  );

  const groupsOperationsAllowed = useIsContractOperationsAllowed();

  const classes = useStyles();

  useEffect(() => {
    dispatch(projectGroupsRetrieveGroups());
  }, [dispatch]);

  const columns = [
    { name: "name", title: "Name" },
    { name: "status", title: "Status" },
    { name: "projects", title: "Projects" }
  ];

  return (
    <Box style={{ display: "flex", justifyContent: "center" }}>
      {fetching ? (
        <CircularProgress className={classes.progress} />
      ) : (
        <Box>
          {groupsOperationsAllowed && (
            <GridContainer>
              <GridItem style={{ marginBottom: 20 }} xs={12}>
                <Link to="/project-group/add">
                  <Fab color="primary" style={{ float: "right" }}>
                    <AddIcon />
                  </Fab>
                </Link>
              </GridItem>
            </GridContainer>
          )}
          <ProjectGroupsGrid rows={projectGroups} columns={columns} />
        </Box>
      )}
    </Box>
  );
};

export default ProjectGroups;
