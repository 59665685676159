import {
  RETRIEVE_ACCOUNT_MANAGERS,
  SET_ACCOUNT_MANAGERS,
  AccountManagersActionTypes,
  AccountManagersState
} from "../types/accountManagers";

const initialState: AccountManagersState = {
  data: [],
  fetching: false
};

export default function(
  state: AccountManagersState = initialState,
  action: AccountManagersActionTypes
): AccountManagersState {
  switch (action.type) {
    case RETRIEVE_ACCOUNT_MANAGERS:
      return { ...state, fetching: true };
    case SET_ACCOUNT_MANAGERS:
      return {
        ...state,
        data: [...action.payload],
        fetching: false
      };
    default:
      return state;
  }
}
