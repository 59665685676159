export enum ProjectStatus {
  Active = "active",
  Paused = "paused",
  Closed = "closed"
}

export type ProjectFormProject = {
  id: number;
  name: string;
  account_manager_id: number | null;
  client: string;
  status: ProjectStatus;
  project_group_id: number | null;
};
