import {
  CONTRACTS_RETRIEVE_CONTRACTS,
  CONTRACTS_SET_CONTRACTS,
  CONTRACTS_RETRIEVE_INACTIVE_CONTRACTS,
  CONTRACTS_SET_INACTIVE_CONTRACTS,
  CONTRACTS_SET_PARAMS,
  CONTRACTS_SET_SORTING,
  CONTRACTS_SET_FILTERS,
  CONTRACTS_SET_GROUPING,
  CONTRACTS_RESET_PARAMS,
  CONTRACTS_RESET_SORTING,
  CONTRACTS_RESET_FILTERS,
  CONTRACTS_RESET_GROUPING
} from "../actionTypes";

const initialState = {
  list: [],
  fetching: false,
  params: {
    accountManagerId: 0
  },
  filters: [{ columnName: "status", value: "active" }],
  sorting: [{ columnName: "start_date", direction: "desc" }],
  grouping: [],
  inactive: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case CONTRACTS_SET_CONTRACTS:
      return { ...state, list: [...action.payload.contracts], fetching: false };
    case CONTRACTS_SET_INACTIVE_CONTRACTS:
      return {
        ...state,
        inactive: [...action.payload.inactive],
        fetching: false
      };
    case CONTRACTS_RETRIEVE_CONTRACTS:
      return { ...state, fetching: true };
    case CONTRACTS_RETRIEVE_INACTIVE_CONTRACTS:
      return { ...state, fetching: true };
    case CONTRACTS_SET_PARAMS:
      return { ...state, params: { ...action.payload.params } };
    case CONTRACTS_SET_FILTERS:
      return { ...state, filters: [...action.payload.filters] };
    case CONTRACTS_SET_SORTING:
      return { ...state, sorting: [...action.payload.sorting] };
    case CONTRACTS_SET_GROUPING:
      return { ...state, grouping: [...action.payload.grouping] };
    case CONTRACTS_RESET_PARAMS:
      return { ...state, params: { ...initialState.params } };
    case CONTRACTS_RESET_FILTERS:
      return { ...state, filters: [...initialState.filters] };
    case CONTRACTS_RESET_SORTING:
      return { ...state, sorting: [...initialState.sorting] };
    case CONTRACTS_RESET_GROUPING:
      return { ...state, grouping: [...initialState.grouping] };
    default:
      return state;
  }
}
