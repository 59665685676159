import { makeStyles } from "@material-ui/styles";
import TableCell from "@material-ui/core/TableCell";
import { MenuItem, Select } from "@material-ui/core";
import Input from "@material-ui/core/Input";
import React from "react";

const selectFilterCellStyles = theme => ({
  tableCell: {
    width: "100%",
    padding: "8px"
  },
  select: {
    width: "100%"
  }
});

const useSelectFilterCellStyles = makeStyles(selectFilterCellStyles);

function SelectFilterCell(props) {
  const classes = useSelectFilterCellStyles();
  const { filter, onFilter, options } = props;
  return (
    <TableCell className={classes.tableCell}>
      <Select
        value={filter ? filter.value : ""}
        onChange={e =>
          onFilter(e.target.value ? { value: e.target.value } : null)
        }
        input={<Input name="status" id="status-label-placeholder" />}
        displayEmpty
        className={classes.select}
      >
        {options.map(option => (
          <MenuItem key={option.id} value={option.id}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </TableCell>
  );
}

export default SelectFilterCell;
