// Contracts.
export const CONTRACTS_RETRIEVE_CONTRACTS = "RETRIEVE_CONTRACTS";
export const CONTRACTS_SET_CONTRACTS = "SET_CONTRACTS";
export const CONTRACTS_RETRIEVE_INACTIVE_CONTRACTS = "RETRIEVE_INACTIVE_CONTRACTS";
export const CONTRACTS_SET_INACTIVE_CONTRACTS = "SET_INACTIVE_CONTRACTS";
export const CONTRACTS_SET_PARAMS = "CONTRACTS_SET_PARAMS";
export const CONTRACTS_SET_FILTERS = "CONTRACTS_SET_FILTERS";
export const CONTRACTS_SET_SORTING = "CONTRACTS_SET_SORTING";
export const CONTRACTS_SET_GROUPING = "CONTRACTS_SET_GROUPING";
export const CONTRACTS_RESET_PARAMS = "CONTRACTS_RESET_PARAMS";
export const CONTRACTS_RESET_FILTERS = "CONTRACTS_RESET_FILTERS";
export const CONTRACTS_RESET_SORTING = "CONTRACTS_RESET_SORTING";
export const CONTRACTS_RESET_GROUPING = "CONTRACTS_RESET_GROUPING";

// Projects.
export const PROJECTS_RETRIEVE_PROJECTS = "RETRIEVE_PROJECTS";
export const PROJECTS_SET_PROJECTS = "SET_PROJECTS";
export const PROJECTS_RETRIEVE_INACTIVE_PROJECTS = "RETRIEVE_INACTIVE_PROJECTS";
export const PROJECTS_SET_INACTIVE_PROJECTS = "SET_INACTIVE_PROJECTS";
export const PROJECTS_SET_PARAMS = "PROJECTS_SET_PARAMS";
export const PROJECTS_SET_FILTERS = "PROJECTS_SET_FILTERS";
export const PROJECTS_SET_SORTING = "PROJECTS_SET_SORTING";
export const PROJECTS_SET_GROUPING = "PROJECTS_SET_GROUPING";
export const PROJECTS_RESET_PARAMS = "PROJECTS_RESET_PARAMS";
export const PROJECTS_RESET_FILTERS = "PROJECTS_RESET_FILTERS";
export const PROJECTS_RESET_SORTING = "PROJECTS_RESET_SORTING";
export const PROJECTS_RESET_GROUPING = "PROJECTS_RESET_GROUPING";

// Developers.
export const RETRIEVE_DEVELOPERS = "RETIRIEVE_DEVELOPERS";
export const SET_DEVELOPERS = "SET_DEVELOPERS";

// Time reports.
export const TIME_REPORTS_RETRIEVE_TIME_REPORTS =
  "TIME_REPORTS_RETRIEVE_TIME_REPORTS";
export const TIME_REPORTS_SET_TIME_REPORTS = "TIME_REPORTS_SET_TIME_REPORTS";
export const TIME_REPORTS_SET_PARAMS = "TIME_REPORTS_SET_PARAMS";
export const TIME_REPORTS_SET_FILTERS = "TIME_REPORTS_SET_FILTERS";
export const TIME_REPORTS_SET_SORTING = "TIME_REPORTS_SET_SORTING";
export const TIME_REPORTS_SET_PAGER = "TIME_REPORTS_SET_PAGER";
export const TIME_REPORTS_RESET_PARAMS = "TIME_REPORTS_RESET_PARAMS";
export const TIME_REPORTS_RESET_FILTERS = "TIME_REPORTS_RESET_FILTERS";
export const TIME_REPORTS_RESET_SORTING = "TIME_REPORTS_RESET_SORTING";
export const TIME_REPORTS_RESET_PAGER = "TIME_REPORTS_RESET_PAGER";

// Daily time reports.
export const DAILY_TIME_REPORTS_RETRIEVE_TIME_REPORTS =
  "DAILY_TIME_REPORTS_RETRIEVE_TIME_REPORTS";
export const DAILY_TIME_REPORTS_SET_TIME_REPORTS =
  "DAILY_TIME_REPORTS_SET_TIME_REPORTS";
export const DAILY_TIME_REPORTS_SET_PARAMS = "DAILY_TIME_REPORTS_SET_PARAMS";
export const DAILY_TIME_REPORTS_SET_FILTERS = "DAILY_TIME_REPORTS_SET_FILTERS";
export const DAILY_TIME_REPORTS_SET_SORTING = "DAILY_TIME_REPORTS_SET_SORTING";
export const DAILY_TIME_REPORTS_SET_PAGER = "DAILY_TIME_REPORTS_SET_PAGER";
export const DAILY_TIME_REPORTS_RESET_PARAMS =
  "DAILY_TIME_REPORTS_RESET_PARAMS";
export const DAILY_TIME_REPORTS_RESET_FILTERS =
  "DAILY_TIME_REPORTS_RESET_FILTERS";
export const DAILY_TIME_REPORTS_RESET_SORTING =
  "DAILY_TIME_REPORTS_RESET_SORTING";
export const DAILY_TIME_REPORTS_RESET_PAGER = "DAILY_TIME_REPORTS_RESET_PAGER";

// Time report form.
export const TIME_REPORT_FORM_RETRIEVE_TIME_REPORT =
  "TIME_REPORT_FORM_RETRIEVE_TIME_REPORT";
export const TIME_REPORT_FORM_SET_CONTRACT = "TIME_REPORT_FORM_SET_CONTRACT";
export const TIME_REPORT_FORM_SET_DEVELOPER = "TIME_REPORT_FORM_SET_DEVELOPER";
export const TIME_REPORT_FORM_SET_TIME_REPORT =
  "TIME_REPORT_FORM_SET_TIME_REPORT";
export const TIME_REPORT_FORM_SEND_TIME_REPORT =
  "TIME_REPORT_FORM_SEND_TIME_REPORT";
export const TIME_REPORT_FORM_DELETE_TIME_REPORT =
  "TIME_REPORT_FORM_DELETE_TIME_REPORT";
export const TIME_REPORT_FORM_SET_RETRIEVE_TIME_REPORT_ERRORS =
  "TIME_REPORT_FORM_SET_RETRIEVE_TIME_REPORT_ERRORS";
export const TIME_REPORT_FORM_SET_SEND_TIME_REPORT_ERRORS =
  "TIME_REPORT_FORM_SET_SEND_TIME_REPORT_ERRORS";
export const TIME_REPORT_FORM_RESET_TIME_REPORT =
  "TIME_REPORT_FORM_RESET_TIME_REPORT";
export const TIME_REPORT_FORM_RESET_CONTRACT =
  "TIME_REPORT_FORM_RESET_CONTRACT";
export const TIME_REPORT_FORM_RESET_RETRIEVE_TIME_REPORT_ERRORS =
  "TIME_REPORT_FORM_RESET_RETRIEVE_TIME_REPORT_ERRORS";
export const TIME_REPORT_FORM_RESET_SEND_TIME_REPORT_ERRORS =
  "TIME_REPORT_FORM_RESET_SEND_TIME_REPORT_ERRORS";

// Configuration.
export const CONFIG_RETRIEVE_CONTRACT_CURRENCIES =
  "CONFIG_RETRIEVE_CONTRACT_CURRENCIES";
export const CONFIG_SET_CONTRACT_CURRENCIES = "CONFIG_SET_CONTRACT_CURRENCIES";

export const SET_ACCOUNT_MANAGERS = "SET_ACCOUNT_MANAGERS";
export const RETRIEVE_ACCOUNT_MANAGERS = "RETRIEVE_ACCOUNT_MANAGERS";
