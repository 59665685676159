import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  retrieveDevelopers,
  timeReportFormRetrieveTimeReport,
  timeReportFormSetTimeReport,
  timeReportFormSendTimeReport,
  timeReportFormDeleteTimeReport,
  timeReportFormResetTimeReport,
  timeReportFormSetDeveloper,
  timeReportFormSetContract,
  timeReportFormResetContract,
  timeReportFormResetRetrieveTimeReportErrors,
  timeReportFormResetSendTimeReportErrors
} from "redux/actions";
import {
  Button,
  CircularProgress,
  FormControl,
  FormLabel,
  Grid,
  Paper,
  Snackbar,
  SnackbarContent,
  TextField,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions
} from "@material-ui/core";
import dataFetcher from "services/dataFetcher";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import moment from "moment";
import { makeStyles } from "@material-ui/styles";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import { useIsTimeReportOperationsAllowed } from "../../hooks";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Link } from "react-router-dom";
import { green } from "@material-ui/core/colors";
import { whiteColor } from "../../assets/jss/material-dashboard-react";

const timeReportFormStyles = theme => ({
  formPaper: {
    padding: "10px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column"
  },
  buttonSpinner: {
    margin: "5px"
  },
  form: {
    width: "100%"
  },
  snackbarSuccess: {
    ...theme.snackBarSuccess,
    ...theme.snackBar
  },
  snackbarError: {
    ...theme.snackBarError,
    ...theme.snackBar
  },
  snackbarMessage: {
    ...theme.snackBarMessage
  },
  snackbarIcon: {
    ...theme.snackbarIcon
  },
  deleteButton: {
    marginLeft: "15px"
  },
  newReportButton: {
    marginLeft: "15px",
    color: whiteColor,
    backgroundColor: green[600],
    "&:hover": {
      backgroundColor: green[700],
      color: whiteColor
    }
  }
});

const useStyles = makeStyles(timeReportFormStyles);

function TimeReportForm(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { match, history } = props;
  const timeReportId =
    match.params.id !== undefined ? parseInt(match.params.id) : 0;
  const isTimeReportOperationsAllowed = useIsTimeReportOperationsAllowed();

  const [contracts, setContracts] = useState([]);
  const [sendTimeReportAttempt, setSendTimeReportAttempt] = useState(false);
  const [deleteTimeReportAttempt, setDeleteTimeReportAttempt] = useState(false);
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [openDeleteReportDialog, setOpenDeleteReportDialog] = useState(false);

  const user = useSelector(state => state.currentUser.user);
  const developers = useSelector(state => state.developers.list);
  const developersFetching = useSelector(state => state.developers.fetching);
  const timeReport = useSelector(state => state.timeReportForm.timeReport);
  const contract = useSelector(state => state.timeReportForm.contract);
  const developer = useSelector(state => state.timeReportForm.developer);
  const timeReportFetching = useSelector(
    state => state.timeReportForm.fetching
  );
  const timeReportSending = useSelector(state => state.timeReportForm.sending);
  const retrieveErrors = useSelector(
    state => state.timeReportForm.retrieveErrors
  );
  const sendErrors = useSelector(state => state.timeReportForm.sendErrors);

  const isFormAvailable =
    isTimeReportOperationsAllowed ||
    timeReportId === 0 ||
    developer.uid === user.uid;

  useEffect(() => {
    dispatch(timeReportFormResetRetrieveTimeReportErrors());
    dispatch(timeReportFormResetSendTimeReportErrors());
    if (timeReportId !== 0) {
      dispatch(timeReportFormRetrieveTimeReport(timeReportId));
    } else {
      dispatch(timeReportFormResetTimeReport());
    }
  }, [dispatch, timeReportId]);
  useEffect(() => {
    if (developers.length === 0) {
      dispatch(retrieveDevelopers());
    }
  }, [developers, dispatch]);
  useEffect(() => {
    if (timeReportId === 0 && !isTimeReportOperationsAllowed) {
      dispatch(timeReportFormSetDeveloper(user));
    }
  }, [dispatch, timeReportId, user, isTimeReportOperationsAllowed]);
  useEffect(() => {
    if (developerValue !== null) {
      dataFetcher.contracts
        .getContracts({ developer_id: developerValue.value })
        .then(result => {
          if (result.data.status === "success") {
            setContracts([...result.data.data]);
          }
        });
    }
  }, [developer.uid]);

  const developersOptions = useMemo(() => {
    // Since our dev list contains only active developers we should take into account the case when chosen developer is absent.
    let isDeveloperInList = false;
    const developersOptions = developers.map(developerInList => {
      isDeveloperInList = developerInList.uid === developer.uid;
      return {
        value: developerInList.uid,
        label: developerInList.name
      };
    });
    if (!isDeveloperInList) {
      // If there is no current developer in list we should add it.
      developersOptions.push({ value: developer.uid, label: developer.name });
    }
    return developersOptions;
  }, [developer, developers]);
  let params = new URLSearchParams(document.location.search);
  const developerValue = useMemo(() => {
    const developerValueCandidates = developersOptions.filter(
      developersOption => params.get('devName') ?
          params.get('devName') === developersOption.label :
          developer.uid === developersOption.value
    );
    let developerValue = null;
    if (developerValueCandidates.length > 0) {
      developerValue = developerValueCandidates.shift();
    }

    if (developerValue !== null && developer.uid === 0) {
      setDeveloper(developerValue.value);
    }

    return developerValue;
  }, [developer, developersOptions]);

  const contractOptions = useMemo(() => {
    const statusPriorities = {
      active: 1,
      paused: 2,
      closed: 3
    };
    // We should sort contracts by status first and then alphabetically.
    const sortedContracts = contracts.sort((contractA, contractB) => {
      if (
        statusPriorities[contractA.status] ===
        statusPriorities[contractB.status]
      ) {
        if (contractA.name.toLowerCase() === contractB.name.toLowerCase()) {
          return 0;
        } else {
          return contractA.name.toLowerCase() < contractB.name.toLowerCase()
            ? -1
            : 1;
        }
      } else {
        return statusPriorities[contractA.status] <
          statusPriorities[contractB.status]
          ? -1
          : 1;
      }
    });
    return sortedContracts.map(contract => ({
      value: contract.id,
      label: `${contract.name} (${contract.status})`
    }));
  }, [contracts]);
  const contractValue = useMemo(() => {
    const contractValueCandidates = contractOptions.filter(
      contractOption => params.get('contract') ?
        params.get('contract') === contractOption.label.substring(0, contractOption.label.length - 9) :
        contractOption.value === contract.id
    );
    let contractValue = null;
    if (contractValueCandidates.length > 0) {
      contractValue = contractValueCandidates.shift();
    }

    if (contractValue !== null && contract.id === 0) {
      setContract(contractValue.value);
    }

    return contractValue;
  }, [contract, contractOptions]);

  const formErrors = [];
  let fieldsErrors = {};
  if (sendErrors !== null) {
    if (Array.isArray(sendErrors)) {
      for (const error of sendErrors) {
        formErrors.push(<FormLabel error={true}>{error}</FormLabel>);
      }
    } else {
      formErrors.push(
        <FormLabel error={true}>Please fix all errors</FormLabel>
      );
      fieldsErrors = sendErrors;
    }
  }

  if (sendTimeReportAttempt && !timeReportSending) {
    // If we just created a contract, then we need to redirect on contract form.
    if (timeReportId === 0 && timeReport.id !== 0) {
      history.push(`/time-report/${timeReport.id}`);
    }
    setSendTimeReportAttempt(false);
    setShowSnackBar(true);
  }

  function handleSaveTimeReport() {
    setSendTimeReportAttempt(true);
    dispatch(timeReportFormSendTimeReport());
  }

  function setDeveloper(developerId) {
    const developer = developers.find(
        developerInList => developerInList.uid === developerId);

    if (developer !== undefined) {
      dispatch(timeReportFormSetDeveloper(developer));
    }

    dispatch(timeReportFormResetContract());
  }

  function setContract(contractId) {
    const contract = contracts.find(
        contractInList => contractInList.id === contractId
    );

    if (contract !== undefined) {
      dispatch(timeReportFormSetContract(contract));
    }
  }

  const form = (
    <form className={classes.form}>
      <Grid container spacing={2}>
        {formErrors.length > 0 && (
          <Grid item xs={12}>
            {formErrors}
          </Grid>
        )}
        <Grid item xs={12}>
          <FormControl required fullWidth>
            <Autocomplete
              id="developer"
              options={developersOptions}
              value={developerValue}
              getOptionLabel={option => option.label}
              onChange={(event, developer) => {
                if (developer !== null) {
                  setDeveloper(developer.value);
                }
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  error={
                    !Array.isArray(fieldsErrors) &&
                    fieldsErrors.developer_id !== undefined
                  }
                  label="Developer"
                  variant="standard"
                  placeholder="Choose a client"
                  required
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl required fullWidth>
            <Autocomplete
              id="contract"
              options={contractOptions}
              value={contractValue}
              getOptionLabel={option => option.label}
              onChange={(event, contract) => {
                if (contract !== null) {
                  setContract(contract.value);
                }
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  error={
                    !Array.isArray(fieldsErrors) &&
                    fieldsErrors.developer_id !== undefined
                  }
                  label="Contract"
                  variant="standard"
                  placeholder="Choose a client"
                  required
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disabled={timeReportId !== 0}
              disableToolbar
              required
              variant="dialog"
              format="dd/MM/yyyy"
              id="day-worked-on"
              ampm={false}
              inputVariant={"standard"}
              label="Day worked on"
              value={params.get('date') ? params.get('date') : timeReport.day_worked_on}
              error={fieldsErrors.day_worked_on !== undefined}
              helperText={
                fieldsErrors.day_worked_on !== undefined
                  ? fieldsErrors.day_worked_on
                  : ""
              }
              onChange={date => {
                dispatch(
                  timeReportFormSetTimeReport({
                    ...timeReport,
                    day_worked_on: moment(date).format("YYYY-MM-DD")
                  })
                );
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12} md={timeReportId === 0 ? 2 : 12}>
          <TextField
            id="hours"
            name="hours"
            fullWidth
            label="Hours"
            step="2"
            required
            value={timeReport.hours}
            onChange={event => {
              dispatch(
                timeReportFormSetTimeReport({
                  ...timeReport,
                  hours: event.target.value
                })
              );
            }}
            error={fieldsErrors.hours !== undefined}
            helperText={
              fieldsErrors.hours !== undefined ? sendErrors.hours : ""
            }
          />
        </Grid>
        {timeReportId === 0 && (
          <Grid item xs={12} md={2}>
            <TextField
              id="minutes"
              name="minutes"
              fullWidth
              label="Minutes"
              step="0"
              value={timeReport.minutes}
              onChange={event => {
                dispatch(
                  timeReportFormSetTimeReport({
                    ...timeReport,
                    minutes: event.target.value
                  })
                );
              }}
              error={fieldsErrors.minutes !== undefined}
              helperText={
                fieldsErrors.minutes !== undefined ? sendErrors.hours : ""
              }
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={event => {
              handleSaveTimeReport();
              event.preventDefault();
            }}
          >
            {timeReportId === 0 ? "Log time" : "Save Report"}
          </Button>
          {timeReportId !== 0 && (
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              onClick={event => {
                setOpenDeleteReportDialog(true);
                event.preventDefault();
              }}
              className={classes.deleteButton}
            >
              Delete Report
            </Button>
          )}
          {timeReportId !== 0 && (
            <Link to="/time-report/add">
              <Button
                color="inherit"
                className={classes.newReportButton}
                variant="contained"
              >
                New Report
              </Button>
            </Link>
          )}
        </Grid>
      </Grid>
    </form>
  );

  const snackBar = (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left"
      }}
      open={showSnackBar}
      onClose={(event, reason) => {
        if (reason === "timeout") {
          setShowSnackBar(false);
        }
      }}
      autoHideDuration={2000}
    >
      <SnackbarContent
        aria-describedby="snackbar-message"
        className={
          formErrors.length === 0
            ? classes.snackbarSuccess
            : classes.snackbarError
        }
        message={
          <span id="snackbar-message" className={classes.snackbarMessage}>
            {formErrors.length === 0 ? (
              <CheckCircleIcon className={classes.snackbarIcon} />
            ) : (
              <ErrorIcon className={classes.snackbarIcon} />
            )}
            {formErrors.length === 0
              ? "Time report saved!"
              : "Time report not saved"}
          </span>
        }
      />
    </Snackbar>
  );

  function handleCloseDeleteReportDialog() {
    setOpenDeleteReportDialog(false);
  }

  if (deleteTimeReportAttempt && !timeReportSending) {
    // If we just removed a contract, then we need to redirect on dashboard page.
    if (timeReport.id === 0) {
      history.push("/");
    }
    setOpenDeleteReportDialog(false);
    setDeleteTimeReportAttempt(false);
  }

  function handleDeleteTimeReport() {
    setDeleteTimeReportAttempt(true);
    dispatch(timeReportFormDeleteTimeReport());
  }

  const deleteReportDialog = (
    <Dialog
      open={openDeleteReportDialog}
      onClose={handleCloseDeleteReportDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure that you want to delete this Time Report?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDeleteTimeReport} color="primary">
          Yes
        </Button>
        <Button
          onClick={handleCloseDeleteReportDialog}
          color="secondary"
          autoFocus
        >
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
  const errorMessages = [];
  if (retrieveErrors !== null) {
    if (Array.isArray(retrieveErrors)) {
      for (const error of retrieveErrors) {
        errorMessages.push(<FormLabel error={true}>{error}</FormLabel>);
      }
    } else {
      for (const [key, error] of Object.entries(retrieveErrors)) {
        errorMessages.push(
          <FormLabel error={true}>
            {key}: {error}
          </FormLabel>
        );
      }
    }
  }

  return (
    <div>
      {isFormAvailable ? (
        <Paper className={classes.formPaper}>
          {(developersFetching || timeReportFetching) && (
            <CircularProgress className={classes.progress} />
          )}
          {errorMessages.length > 0 && <div>{errorMessages}</div>}
          {form}
          {snackBar}
          {deleteReportDialog}
        </Paper>
      ) : (
        "Forbidden"
      )}
    </div>
  );
}

export default TimeReportForm;
