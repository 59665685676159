import React from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { contractFormSetContract } from "redux/actions";
import { ContractFormContract, ContractStatus } from "../../types/contract";
import { ContractFormErrors } from "../../redux/types/contractForm";
import { ProjectFormProject } from "types/project";

interface ProjectsDropDownProps {
  contract: ContractFormContract;
  isBeingEdited: boolean;
  fieldsErrors: ContractFormErrors;
}

export default function ProjectsDropDown({
  contract,
  isBeingEdited,
  fieldsErrors
}: ProjectsDropDownProps) {
  const dispatch = useDispatch();

  const projects: ProjectFormProject[] = useSelector(
    // @ts-ignore
    state => state.projects.list
  );

  function getProjectMenuItemColor(status: string) {
    switch (status) {
      case "Active":
        return "rgb(217, 234, 211)";
      case "Paused":
        return "rgb(255, 242, 204)";
      default:
        return "rgb(244, 204, 204)";
    }
  }

  return (
    <FormControl
      disabled={!isBeingEdited}
      required
      fullWidth
      error={fieldsErrors.project_id !== undefined}
    >
      <InputLabel htmlFor="project_id">Project</InputLabel>
      <Select
        value={contract.project_id}
        onChange={event => {
          dispatch(
            contractFormSetContract({
              ...contract,
              project_id: event.target.value as number
            })
          );
        }}
        inputProps={{
          name: "project_id",
          id: "project_id",
          fullWidth: true,
          required: true
        }}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 500,
              width: 250
            }
          }
        }}
      >
        {projects.map(({ id, name, status }) => (
          <MenuItem
            key={id}
            value={id}
            style={{
              backgroundColor: getProjectMenuItemColor(status)
            }}
          >
            {name}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{fieldsErrors.project_id || ""}</FormHelperText>
    </FormControl>
  );
}
