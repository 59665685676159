import {
  CONTRACT_FORM_RESET_CONTRACT,
  CONTRACT_FORM_RESET_RETRIEVE_CONTRACT_ERRORS,
  CONTRACT_FORM_RESET_SEND_CONTRACT_ERRORS,
  CONTRACT_FORM_RETRIEVE_CLOCKIFY_PROJECTS,
  CONTRACT_FORM_RETRIEVE_CLOCKIFY_WORKSPACES,
  CONTRACT_FORM_RETRIEVE_CONTRACT,
  CONTRACT_FORM_RETRIEVE_TOGGL_PROJECTS,
  CONTRACT_FORM_RETRIEVE_TOGGL_WORKSPACES,
  CONTRACT_FORM_SEND_CONTRACT,
  CONTRACT_FORM_SET_CLOCKIFY_PROJECTS,
  CONTRACT_FORM_SET_CLOCKIFY_WORKSPACES,
  CONTRACT_FORM_SET_CONTRACT,
  CONTRACT_FORM_SET_NO_CLOCKIFY_KEY,
  CONTRACT_FORM_SET_NO_TOGGL_KEY,
  CONTRACT_FORM_SET_RETRIEVE_CONTRACT_ERRORS,
  CONTRACT_FORM_SET_SEND_CONTRACT_ERRORS,
  CONTRACT_FORM_SET_TOGGL_PROJECTS,
  CONTRACT_FORM_SET_TOGGL_WORKSPACES,
  ContractFormActionTypes
} from "../types/contractForm";
import { ContractFormContract } from "../../types/contract";
import { Errors } from "../types/contractForm";

export function contractFormRetrieveContract(
  id: number
): ContractFormActionTypes {
  return {
    type: CONTRACT_FORM_RETRIEVE_CONTRACT,
    payload: id
  };
}

export function contractFormSetContract(
  contract: ContractFormContract
): ContractFormActionTypes {
  return {
    type: CONTRACT_FORM_SET_CONTRACT,
    payload: contract
  };
}

export function contractFormSendContract(
  contract: ContractFormContract
): ContractFormActionTypes {
  return {
    type: CONTRACT_FORM_SEND_CONTRACT,
    payload: contract
  };
}

export function contractFormSetRetrieveContractErrors(
  errors: Errors
): ContractFormActionTypes {
  return {
    type: CONTRACT_FORM_SET_RETRIEVE_CONTRACT_ERRORS,
    payload: errors
  };
}

export function contractFormSetSendContractErrors(
  errors: Errors
): ContractFormActionTypes {
  return {
    type: CONTRACT_FORM_SET_SEND_CONTRACT_ERRORS,
    payload: errors
  };
}

export function contractFormResetContract(): ContractFormActionTypes {
  return {
    type: CONTRACT_FORM_RESET_CONTRACT
  };
}

export function contractFormResetRetrieveContractErrors(): ContractFormActionTypes {
  return {
    type: CONTRACT_FORM_RESET_RETRIEVE_CONTRACT_ERRORS
  };
}

export function contractFormResetSendContractErrors(): ContractFormActionTypes {
  return {
    type: CONTRACT_FORM_RESET_SEND_CONTRACT_ERRORS
  };
}

export function contractFormSetTogglWorkspaces(workspaces: {
  [key: number]: string;
}): ContractFormActionTypes {
  return {
    type: CONTRACT_FORM_SET_TOGGL_WORKSPACES,
    payload: workspaces
  };
}

export function contractFormSetTogglProjects(projects: {
  [key: number]: string;
}): ContractFormActionTypes {
  return {
    type: CONTRACT_FORM_SET_TOGGL_PROJECTS,
    payload: projects
  };
}

export function contractFormRetrieveTogglWorkspaces(
  developerId: number
): ContractFormActionTypes {
  return {
    type: CONTRACT_FORM_RETRIEVE_TOGGL_WORKSPACES,
    payload: developerId
  };
}

export function contractFormRetrieveTogglProjects(
  developerId: number,
  workspaceId: number
): ContractFormActionTypes {
  return {
    type: CONTRACT_FORM_RETRIEVE_TOGGL_PROJECTS,
    payload: {
      developerId,
      workspaceId
    }
  };
}

export function contractFormSetNoTogglKey(): ContractFormActionTypes {
  return {
    type: CONTRACT_FORM_SET_NO_TOGGL_KEY
  };
}

export function contractFormSetClockifyWorkspaces(workspaces: {
  [key: number]: string;
}): ContractFormActionTypes {
  return {
    type: CONTRACT_FORM_SET_CLOCKIFY_WORKSPACES,
    payload: workspaces
  };
}

export function contractFormSetClockifyProjects(projects: {
  [key: number]: string;
}): ContractFormActionTypes {
  return {
    type: CONTRACT_FORM_SET_CLOCKIFY_PROJECTS,
    payload: projects
  };
}

export function contractFormRetrieveClockifyWorkspaces(
  developerId: number
): ContractFormActionTypes {
  return {
    type: CONTRACT_FORM_RETRIEVE_CLOCKIFY_WORKSPACES,
    payload: developerId
  };
}

export function contractFormRetrieveClockifyProjects(
  developerId: number,
  workspaceId: number
): ContractFormActionTypes {
  return {
    type: CONTRACT_FORM_RETRIEVE_CLOCKIFY_PROJECTS,
    payload: {
      developerId,
      workspaceId
    }
  };
}

export function contractFormSetNoClockifyKey(): ContractFormActionTypes {
  return {
    type: CONTRACT_FORM_SET_NO_CLOCKIFY_KEY
  };
}
