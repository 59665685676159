import React, { useEffect } from "react";
import PropTypes from "prop-types";
// @material-ui/core
import {
  Input,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Box,
  CircularProgress,
  Fab,
  Button
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import AddIcon from "@material-ui/icons/Add";
import contractsStyle from "assets/jss/material-dashboard-react/views/contractsStyle.jsx";
import ProjectsGrid from "components/Projects/ProjectsGrid.jsx";
import { useIsContractOperationsAllowed } from "hooks";
import { useDispatch, useSelector } from "react-redux";
import {
  projectsRetrieveProjects,
  projectsSetParams,
  projectsSetFilters,
  projectsSetSorting,
  projectsSetGrouping,
  projectsResetParams,
  projectsResetFilters,
  projectsResetSorting,
  projectsResetGrouping,
  retrieveAccountManagers,
  contractsRetrieveContracts,
} from "redux/actions";
import { Link } from "react-router-dom";
import {
  CONTRACT_PROVIDERS,
  CONTRACT_STATUSES,
  CONTRACT_TYPES,
  ACCOUNT_MANAGERS
} from "../../variables/general";
import SelectFilterCell from "../../components/Grid/SelectFilterCell";
import { TableFilterRow } from "@devexpress/dx-react-grid-material-ui";
import { Role } from "types/user";

function FilterCell(props) {
  const { column } = props;
  const accountManagers = useSelector(state => state.accountManagers.data);

  switch (column.name) {
    case "status":
      return (
        <SelectFilterCell
          options={[{ id: "", label: "All" }, ...CONTRACT_STATUSES]}
          {...props}
        />
      );
    case "account_manager_id": {
      let options = accountManagers.map(accountManager => ({
        id: accountManager.name,
        label: accountManager.name
      }));
      options = [{ id: "", label: "All" }, ...options];
      return <SelectFilterCell options={options} {...props} />;
    }
    default:
      return <TableFilterRow.Cell {...props} />;
  }
}

const useStyles = makeStyles(contractsStyle);

function Projects(props) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const currentUser = useSelector(state => state.currentUser.user);
  const projects = useSelector(state => state.projects.list);
  const contracts = useSelector(state => state.contracts.list);
  const fetchingContracts = useSelector(state => state.contracts.fetching);
  const accountManagers = useSelector(state => state.accountManagers.data);
  const fetchingAccountManagers = useSelector(
    state => state.accountManagers.fetching
  );
  const params = useSelector(state => state.projects.params);
  const filters = useSelector(state => state.projects.filters);
  const sorting = useSelector(state => state.projects.sorting);
  const grouping = useSelector(state => state.projects.grouping);

  const contractOperationsAllowed = useIsContractOperationsAllowed();

  useEffect(() => {
    dispatch(contractsRetrieveContracts());
    dispatch(projectsRetrieveProjects());
  }, [dispatch]);

  useEffect(() => {
    if (accountManagers.length === 0) {
      dispatch(retrieveAccountManagers());
    }
  }, [accountManagers, dispatch]);

  const columns = [
    {
      name: "name",
      title: "Project"
    },
    {
      name: "client",
      title: "Client"
    },
    {
      name: "account_manager_id",
      title: "Account Manager",
      getCellValue: row => {
        let value = null;
        if (Boolean(row.account_manager_id)) {
          const accountManager = accountManagers.find(
            accountManager => accountManager.uid === row.account_manager_id
          );
          if (Boolean(accountManager)) {
            value = accountManager.name;
          }
        }
        return value;
      }
    },
    {
      name: "status",
      title: "Status"
    }
  ];

  const grid = (
    <ProjectsGrid
      rows={projects}
      columns={columns}
      columnExtensions={[
        { columnName: "name" },
        { columnName: "client"},
        { columnName: "account_manager_id" },
        { columnName: "status", width: "9%" },
      ]}
      onSortingChange={sorting => {
        dispatch(projectsSetSorting(sorting));
      }}
      sorting={sorting}
      filters={filters}
      onFiltersChange={filters => {
        dispatch(projectsSetFilters(filters));
      }}
      filterCellComponent={FilterCell}
      grouping={grouping}
      onGroupingChange={grouping => {
        dispatch(projectsSetGrouping(grouping));
      }}
    />
  );

  return (
    <div>
      <form className={classes.filtersForm} autoComplete="off">
        <GridContainer>
          <GridItem xs={12} sm={4} md={3}>
            <FormControl className={classes.formControl} fullWidth>
              <InputLabel shrink htmlFor="account-manager-label-placeholder">
                Account manager
              </InputLabel>
              <Select
                value={params.accountManagerId}
                onChange={event => {
                  dispatch(
                    projectsSetParams({
                      ...params,
                      accountManagerId: event.target.value
                    })
                  );
                  dispatch(projectsRetrieveProjects());
                }}
                input={
                  <Input
                    name="account-manager"
                    id="account-manager-label-placeholder"
                  />
                }
                displayEmpty
                name="account-manager"
                className={classes.selectEmpty}
              >
                <MenuItem value={0}>
                  <em>All</em>
                </MenuItem>
                {ACCOUNT_MANAGERS.map(accountManager => (
                  <MenuItem key={accountManager.uid} value={accountManager.uid}>
                    {accountManager.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </GridItem>
          <GridItem container alignItems="center" xs={12} sm={4} md={3}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={event => {
                dispatch(projectsResetParams());
                dispatch(projectsResetFilters());
                dispatch(projectsResetSorting());
                dispatch(projectsResetGrouping());
                dispatch(projectsRetrieveProjects());
                event.preventDefault();
              }}
            >
              Reset filters
            </Button>
          </GridItem>
          {contractOperationsAllowed && (
            <GridItem xs={12} sm={4} md={6}>
              <Link to="/project/add">
                <Fab color="primary" style={{ float: "right" }}>
                  <AddIcon />
                </Fab>
              </Link>
            </GridItem>
          )}
        </GridContainer>
      </form>
      <Box style={{ display: "flex", justifyContent: "center" }}>
        {fetchingContracts || fetchingAccountManagers ? (
          <CircularProgress className={classes.progress} />
        ) : (
          grid
        )}
      </Box>
    </div>
  );
}

Projects.propTypes = {
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default Projects;
