import React from "react";
import {
  KeyboardDateTimePicker,
  KeyboardDateTimePickerProps,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

export function DateTimePicker(props: KeyboardDateTimePickerProps) {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDateTimePicker
        {...props}
        required={true}
        disableToolbar={true}
        format={"dd/MM/yyyy HH:mm:ss"}
        ampm={false}
        variant="dialog"
        inputVariant="standard"
        KeyboardButtonProps={{
          "aria-label": "change date"
        }}
      />
    </MuiPickersUtilsProvider>
  );
}
