import { RETRIEVE_DEVELOPERS, SET_DEVELOPERS } from "../actionTypes";

const initialState = {
  list: [],
  fetching: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_DEVELOPERS:
      return { list: [...action.payload.developers], fetching: false };
    case RETRIEVE_DEVELOPERS:
      return { ...state, fetching: true };
    default:
      return state;
  }
}
