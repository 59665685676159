import * as actionTypes from "../actionTypes";

export const projectsRetrieveProjects = () => ({
  type: actionTypes.PROJECTS_RETRIEVE_PROJECTS,
  payload: {}
});

export const projectsSetProjects = projects => ({
  type: actionTypes.PROJECTS_SET_PROJECTS,
  payload: { projects }
});

export const projectsSetParams = params => ({
  type: actionTypes.PROJECTS_SET_PARAMS,
  payload: { params }
});

export const projectsSetFilters = filters => ({
  type: actionTypes.PROJECTS_SET_FILTERS,
  payload: { filters }
});

export const projectsSetSorting = sorting => ({
  type: actionTypes.PROJECTS_SET_SORTING,
  payload: { sorting }
});

export const projectsSetGrouping = grouping => ({
  type: actionTypes.PROJECTS_SET_GROUPING,
  payload: { grouping }
});

export const projectsResetParams = () => ({
  type: actionTypes.PROJECTS_RESET_PARAMS,
  payload: {}
});

export const projectsResetFilters = () => ({
  type: actionTypes.PROJECTS_RESET_FILTERS,
  payload: {}
});

export const projectsResetSorting = () => ({
  type: actionTypes.PROJECTS_RESET_SORTING,
  payload: {}
});

export const projectsResetGrouping = () => ({
  type: actionTypes.PROJECTS_RESET_GROUPING,
  payload: {}
});
