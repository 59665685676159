import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Role } from "./types/user";

const ALLOWED_CONTRACT_OPERATIONS_ROLES = [
  Role.HeadOfDepartment,
  Role.Manager,
  Role.StaffingManager
];

const ALLOWED_TIME_REPORT_OPERATIONS_ROLES = [
  Role.HeadOfDepartment,
  Role.Manager,
  Role.StaffingManager
];

export function useIsContractOperationsAllowed(): boolean {
  // @ts-ignore
  const user = useSelector(state => state.currentUser.user);
  return useMemo(() => {
    const userContractOperationRoles = ALLOWED_CONTRACT_OPERATIONS_ROLES.filter(
      value => user.roles.includes(value)
    );
    return userContractOperationRoles.length > 0;
  }, [user]);
}

export function useIsTimeReportOperationsAllowed(): boolean {
  // @ts-ignore
  const user = useSelector(state => state.currentUser.user);
  return useMemo(() => {
    const userContractOperationRoles = ALLOWED_TIME_REPORT_OPERATIONS_ROLES.filter(
      value => user.roles.includes(value)
    );
    return userContractOperationRoles.length > 0;
  }, [user]);
}
