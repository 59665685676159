export enum Role {
  Developer = "developer",
  HeadOfDepartment = "head of department",
  Manager = "manager",
  StaffingManager = "staffing manager"
}

export type SimpleUser = {
  uid: number;
  name: string;
  mail: string;
};

export type Developer = SimpleUser & { manager_id: number };

export type User = Developer & { roles: Role[] };
