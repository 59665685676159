import React from "react";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import { Button, Tooltip } from "@material-ui/core";

function ExportButton({ buttonRef, onToggle, getMessage }) {
  return (
    <Tooltip title={getMessage("showExportMenu")}>
      <Button ref={buttonRef} onClick={onToggle}>
        <SaveAltIcon />
      </Button>
    </Tooltip>
  );
}

export default ExportButton;
