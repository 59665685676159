import {
  CONTRACT_REPORTS_RESET_PARAMS,
  CONTRACT_REPORTS_RETRIEVE_CONTRACT_REPORTS,
  CONTRACT_REPORTS_SET_CONTRACT_REPORTS,
  CONTRACT_REPORTS_SET_PARAMS,
  ContractReportsActionTypes,
  ContractReportsState,
  CONTRACT_REPORTS_SET_FILTERS,
  CONTRACT_REPORTS_RESET_FILTERS,
  CONTRACT_REPORTS_SET_SORTING,
  CONTRACT_REPORTS_RESET_SORTING
} from "../types/contractReports";
import { getMondayDate } from "../../helpers";
import { DATE_FORMAT } from "../../variables/general";
import moment from "moment";

const initialState: ContractReportsState = {
  fetching: false,
  data: [],
  filters: [],
  params: {
    accountManagerId: 0,
    startDate: getMondayDate().format(DATE_FORMAT),
    endDate: moment().format(DATE_FORMAT)
  },
  sorting: [{ columnName: "developerName", direction: "asc" }]
};

export default function(
  state: ContractReportsState = initialState,
  action: ContractReportsActionTypes
): ContractReportsState {
  switch (action.type) {
    case CONTRACT_REPORTS_RETRIEVE_CONTRACT_REPORTS:
      return { ...state, fetching: true };
    case CONTRACT_REPORTS_SET_CONTRACT_REPORTS:
      return { ...state, data: action.payload, fetching: false };
    case CONTRACT_REPORTS_SET_PARAMS:
      return { ...state, params: { ...action.payload } };
    case CONTRACT_REPORTS_SET_FILTERS:
      return { ...state, filters: [...action.payload] };
    case CONTRACT_REPORTS_SET_SORTING:
      return { ...state, sorting: [...action.payload] };
    case CONTRACT_REPORTS_RESET_PARAMS:
      return { ...state, params: { ...initialState.params } };
    case CONTRACT_REPORTS_RESET_FILTERS:
      return { ...state, filters: [...initialState.filters] };
    case CONTRACT_REPORTS_RESET_SORTING:
      return { ...state, sorting: [...initialState.sorting] };
    default:
      return state;
  }
}
