import {
  PROJECT_FORM_RESET_PROJECT,
  PROJECT_FORM_RESET_RETRIEVE_PROJECT_ERRORS,
  PROJECT_FORM_RESET_SEND_PROJECT_ERRORS,
  PROJECT_FORM_RETRIEVE_PROJECT,
  PROJECT_FORM_SEND_PROJECT,
  PROJECT_FORM_SET_PROJECT,
  PROJECT_FORM_SET_RETRIEVE_PROJECT_ERRORS,
  PROJECT_FORM_SET_SEND_PROJECT_ERRORS,
  ProjectFormActionTypes
} from "../types/projectForm";
import { ProjectFormProject } from "../../types/project";
import { Errors } from "../types/projectForm";

export function projectFormRetrieveProject(
  id: number
): ProjectFormActionTypes {
  return {
    type: PROJECT_FORM_RETRIEVE_PROJECT,
    payload: id
  };
}

export function projectFormSetProject(
  project: ProjectFormProject
): ProjectFormActionTypes {
  return {
    type: PROJECT_FORM_SET_PROJECT,
    payload: project
  };
}

export function projectFormSendProject(
  project: ProjectFormProject
): ProjectFormActionTypes {
  return {
    type: PROJECT_FORM_SEND_PROJECT,
    payload: project
  };
}

export function projectFormSetRetrieveProjectErrors(
  errors: Errors
): ProjectFormActionTypes {
  return {
    type: PROJECT_FORM_SET_RETRIEVE_PROJECT_ERRORS,
    payload: errors
  };
}

export function projectFormSetSendProjectErrors(
  errors: Errors
): ProjectFormActionTypes {
  return {
    type: PROJECT_FORM_SET_SEND_PROJECT_ERRORS,
    payload: errors
  };
}

export function projectFormResetProject(): ProjectFormActionTypes {
  return {
    type: PROJECT_FORM_RESET_PROJECT
  };
}

export function projectFormResetRetrieveProjectErrors(): ProjectFormActionTypes {
  return {
    type: PROJECT_FORM_RESET_RETRIEVE_PROJECT_ERRORS
  };
}

export function projectFormResetSendProjectErrors(): ProjectFormActionTypes {
  return {
    type: PROJECT_FORM_RESET_SEND_PROJECT_ERRORS
  };
}
