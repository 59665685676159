import React, { ChangeEvent } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { contractFormSetContract } from "redux/actions";
import { ContractFormContract, ContractStatus } from "../../types/contract";
import { ContractFormErrors } from "../../redux/types/contractForm";
import { getCopyOfObject } from "helpers";

interface ContractStatusPickerProps {
  contract: ContractFormContract;
  isBeingEdited: boolean;
  fieldsErrors: ContractFormErrors;
}

export default function ContractStatusPicker({
  contract,
  isBeingEdited,
  fieldsErrors
}: ContractStatusPickerProps) {
  const dispatch = useDispatch();

  const handleContractStatus = (event: ChangeEvent<{ value: unknown }>) => {
    const contractCopy = getCopyOfObject(contract);
    contractCopy.status = event.target.value as ContractStatus;

    if (contractCopy.status === ContractStatus.Active) {
      const timeIntervalsCount = contractCopy.time_intervals.length;
      contractCopy.time_intervals[timeIntervalsCount - 1].end_date = null;
    }
    dispatch(contractFormSetContract(contractCopy));
  };

  return (
    <FormControl
      disabled={!isBeingEdited}
      fullWidth
      component={"div"}
      error={fieldsErrors.status !== undefined}
      required
    >
      <InputLabel htmlFor="status">Status</InputLabel>
      <Select
        value={contract.status}
        onChange={handleContractStatus}
        inputProps={{
          name: "status",
          id: "status",
          fullWidth: true
        }}
      >
        <MenuItem value={ContractStatus.Active}>Active</MenuItem>
        <MenuItem value={ContractStatus.Paused}>Paused</MenuItem>
        <MenuItem value={ContractStatus.Closed}>Closed</MenuItem>
      </Select>
      <FormHelperText>
        {fieldsErrors.status !== undefined ? fieldsErrors.status : ""}
      </FormHelperText>
    </FormControl>
  );
}
