import {
  CONTRACT_REPORTS_RETRIEVE_CONTRACT_REPORTS,
  CONTRACT_REPORTS_SET_CONTRACT_REPORTS,
  CONTRACT_REPORTS_SET_PARAMS,
  CONTRACT_REPORTS_RESET_PARAMS,
  CONTRACT_REPORTS_SET_FILTERS,
  CONTRACT_REPORTS_RESET_FILTERS,
  ContractReportsParamsState,
  ContractReportsActionTypes,
  CONTRACT_REPORTS_SET_SORTING,
  CONTRACT_REPORTS_RESET_SORTING
} from "../types/contractReports";
import { ContractTimeReport } from "../../types/timeReport";
import { Filter, Sorting } from "@devexpress/dx-react-grid";

export function contractReportsRetrieveContractReports(): ContractReportsActionTypes {
  return {
    type: CONTRACT_REPORTS_RETRIEVE_CONTRACT_REPORTS
  };
}

export function contractReportsSetContractReports(
  contractReports: ContractTimeReport[]
): ContractReportsActionTypes {
  return {
    type: CONTRACT_REPORTS_SET_CONTRACT_REPORTS,
    payload: contractReports
  };
}

export function contractReportsSetParams(
  params: ContractReportsParamsState
): ContractReportsActionTypes {
  return {
    type: CONTRACT_REPORTS_SET_PARAMS,
    payload: params
  };
}

export function contractReportsSetFilters(
  filters: Filter[]
): ContractReportsActionTypes {
  return {
    type: CONTRACT_REPORTS_SET_FILTERS,
    payload: filters
  };
}

export function contractReportsSetSorting(
  sorting: Sorting[]
): ContractReportsActionTypes {
  return {
    type: CONTRACT_REPORTS_SET_SORTING,
    payload: sorting
  };
}

export function contractReportsResetParams(): ContractReportsActionTypes {
  return {
    type: CONTRACT_REPORTS_RESET_PARAMS
  };
}

export function contractReportsResetFilters(): ContractReportsActionTypes {
  return {
    type: CONTRACT_REPORTS_RESET_FILTERS
  };
}

export function contractReportsResetSorting(): ContractReportsActionTypes {
  return {
    type: CONTRACT_REPORTS_RESET_SORTING
  };
}
