import {
  drawerWidth,
  transition,
  container
} from "assets/jss/material-dashboard-react.jsx";
import { grayColor, whiteColor } from "assets/jss/material-dashboard-react";

const appStyle = theme => ({
  wrapper: {
    position: "relative",
    top: "0",
    height: "100vh"
  },
  mainPanel: {
    width: `calc(100% - ${drawerWidth}px)`,
    overflow: "auto",
    position: "relative",
    float: "right",
    ...transition,
    maxHeight: "100%",
    overflowScrolling: "touch"
  },
  content: {
    // marginTop: "70px",
    padding: "30px 15px",
    minHeight: "calc(100vh - 123px)"
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "15px 30px 0 30px"
  },
  container,
  map: {
    marginTop: "70px"
  },
  list: {
    display: "flex",
    flexDirection: "column"
  },
  link: {
    paddingBottom: "4px",
    fontSize: "16px"
  },
  badge: {
    width: "11px",
    height: "11px",
    borderRadius: "6px"
  }
});

export default appStyle;
