import { SimpleUser } from "../../types/user";

export const SET_ACCOUNT_MANAGERS = "SET_ACCOUNT_MANAGERS";
export const RETRIEVE_ACCOUNT_MANAGERS = "RETRIEVE_ACCOUNT_MANAGERS";

type RetrieveAccountManagersAction = {
  type: typeof RETRIEVE_ACCOUNT_MANAGERS;
};

type SetAccountManagersAction = {
  type: typeof SET_ACCOUNT_MANAGERS;
  payload: SimpleUser[];
};

export type AccountManagersActionTypes =
  | RetrieveAccountManagersAction
  | SetAccountManagersAction;

export type AccountManagersState = {
  fetching: boolean;
  data: SimpleUser[];
};
