import React from "react";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
// import FormHelperText from 'material-ui/core/FormHelperText';
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
// import Table from "components/Table/Table.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

class Contract extends React.Component {
  state = {
    contract: {
      name: "szdfsdfsd",
      developer: "",
      type: "",
      status: "",
      rate: ""
    },
    developers: [{ id: 1, name: "name1" }, { id: 2, name: "name2" }]
  };

  handleFormChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({
      contract: {
        ...this.state.contract,
        [name]: value
      }
    });
  };

  render() {
    const classes = this.props.classes;
    return (
      <div>
        <GridContainer>
          <GridContainer xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="warning">
                <h4 className={classes.cardTitleWhite}>Contract</h4>
              </CardHeader>
              <CardBody>
                <form className={classes.root} autoComplete="off">
                  <GridContainer>
                    <GridItem xs={12}>
                      <TextField
                        id="contract-name"
                        name="name"
                        label="Name"
                        disabled={true}
                        // className={classes.textField}
                        value={this.state.contract.name}
                        onChange={this.handleFormChange}
                        margin="normal"
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <TextField
                        id="client-name"
                        label="Name"
                        name="client"
                        // className={classes.textField}
                        value={this.state.contract.client}
                        onChange={this.handleFormChange}
                        margin="normal"
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <TextField
                        id="developer"
                        name="developer"
                        select
                        label="Developer"
                        // className={classes.textField}
                        value={this.state.contract.developer}
                        onChange={this.handleFormChange}
                        // SelectProps={{
                        //   native: true,
                        //   MenuProps: {
                        //     className: classes.menu
                        //   }
                        // }}
                        helperText="Select developer"
                        margin="normal"
                      >
                        {this.state.developers.map(option => (
                          <MenuItem key={option.id} value={option.id}>
                            <em>{option.name}</em>
                          </MenuItem>
                        ))}
                      </TextField>
                    </GridItem>
                    <GridItem xs={12}>
                      <TextField
                        id="type"
                        name="type"
                        select
                        label="Type"
                        // className={classes.textField}
                        value={this.state.contract.type}
                        onChange={this.handleFormChange}
                        // SelectProps={{
                        //   native: true,
                        //   MenuProps: {
                        //     className: classes.menu
                        //   }
                        // }}
                        helperText="Select Type"
                        margin="normal"
                      >
                        <MenuItem value="all">
                          <em>All</em>
                        </MenuItem>
                      </TextField>
                    </GridItem>
                    <GridItem xs={12}>
                      <TextField
                        id="developer"
                        name="developer"
                        select
                        label="Developer"
                        // className={classes.textField}
                        value={this.state.contract.developer}
                        onChange={this.handleFormChange}
                        // SelectProps={{
                        //   native: true,
                        //   MenuProps: {
                        //     className: classes.menu
                        //   }
                        // }}
                        helperText="Please select developer"
                        margin="normal"
                      >
                        {this.state.developers.map(option => (
                          <option key={option.id} value={option.id}>
                            {option.name}
                          </option>
                        ))}
                      </TextField>
                    </GridItem>
                  </GridContainer>
                  <Button variant="contained" color="default" type="submit">
                    Go
                  </Button>
                </form>
              </CardBody>
            </Card>
          </GridContainer>
        </GridContainer>
      </div>
    );
  }
}

Contract.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(Contract);
