import React, { MouseEvent, SetStateAction, Dispatch } from "react";
import { Button, CircularProgress } from "@material-ui/core";
import { useSelector } from "react-redux";

interface SubmitButtonProps {
  isBeingEdited: boolean;
  setIsBeingEdited: Dispatch<SetStateAction<boolean>>;
  contractId: number;
  sendForm: (event: MouseEvent<HTMLButtonElement>) => void;
}

export default function SubmitButton({
  isBeingEdited,
  setIsBeingEdited,
  contractId,
  sendForm
}: SubmitButtonProps) {
  const isNewContract = contractId === 0;

  const contractSending: boolean = useSelector(
    // @ts-ignore
    state => state.contractForm.sending
  );

  return isBeingEdited || isNewContract ? (
    <Button
      type="button"
      variant="contained"
      color="primary"
      onClick={sendForm}
    >
      {isNewContract ? "Create contract" : "Update contract"}
      {contractSending && (
        <CircularProgress
          size={20}
          color="secondary"
          style={{ margin: "5px" }}
        />
      )}
    </Button>
  ) : (
    <Button
      type="button"
      variant="contained"
      color="primary"
      onClick={() => {
        setIsBeingEdited(true);
      }}
    >
      Edit contract
    </Button>
  );
}
