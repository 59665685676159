import React from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { contractFormSetContract } from "redux/actions";
import { ContractFormContract, ContractType } from "../../types/contract";
import { ContractFormErrors } from "../../redux/types/contractForm";

interface ContractTypeDropDownProps {
  contract: ContractFormContract;
  isBeingEdited: boolean;
  fieldsErrors: ContractFormErrors;
}

export function ContractTypeDropDown({
  contract,
  isBeingEdited,
  fieldsErrors
}: ContractTypeDropDownProps) {
  const dispatch = useDispatch();

  return (
    <FormControl
      disabled={!isBeingEdited}
      fullWidth
      component={"div"}
      error={fieldsErrors.type !== undefined}
      required
    >
      <InputLabel htmlFor="type">Type</InputLabel>
      <Select
        value={contract.type}
        onChange={event => {
          const newContract = { ...contract };
          newContract.type = event.target.value as ContractType;
          if (newContract.type !== "fixed") {
            newContract.contract_amount = "";
          }
          if (newContract.type !== "subscription") {
            newContract.daily_workload = "";
          }
          dispatch(contractFormSetContract(newContract));
        }}
        inputProps={{
          name: "type",
          id: "type",
          fullWidth: true
        }}
      >
        <MenuItem value={ContractType.Hourly}>Hourly</MenuItem>
        <MenuItem value={ContractType.Subscription}>Subscription</MenuItem>
        <MenuItem value={ContractType.Fixed}>Fixed Price</MenuItem>
      </Select>
      <FormHelperText>{fieldsErrors.type || ""}</FormHelperText>
    </FormControl>
  );
}
