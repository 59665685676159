import React, { useMemo, useRef, useState } from "react";
import Paper from "@material-ui/core/Paper";
import {
  IntegratedSorting,
  SortingState,
  GroupingState,
  IntegratedGrouping,
  SummaryState,
  IntegratedSummary,
  Column,
  Sorting,
  Grouping,
  SummaryType,
  GroupSummaryItem,
  GroupKey,
  Filter,
  FilteringState,
  IntegratedFiltering
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
  TableGroupRow,
  GroupingPanel,
  TableFilterRow,
  Toolbar,
  ExportPanel
} from "@devexpress/dx-react-grid-material-ui";
import { GridExporter } from "@devexpress/dx-react-grid-export";
import { ContractTimeReport } from "../../types/timeReport";
import { useIsContractOperationsAllowed } from "../../hooks";
import clsx from "clsx";
import { ROUTER_BASENAME } from "../../variables/general";
import { makeStyles } from "@material-ui/styles";
import saveAs from "file-saver";
import SortButton from "../CustomButtons/SortButton";
import GroupButton from "../CustomButtons/GroupButton";
import ExportButton from "components/CustomButtons/ExportButton";

const tableRowStyles = (theme: any) => ({
  tableRow: {
    cursor: "pointer"
  },
  hovered: {
    backgroundColor: theme.palette.primary.main
  },
  hoveredCell: {
    color: "white"
  }
});

const useTableRowStyles = makeStyles(tableRowStyles);

function TableRow({ row, children, ...restProps }: any) {
  const classes = useTableRowStyles();
  const [hovered, setHovered] = useState(false);
  const canEditContract = useIsContractOperationsAllowed();
  const contractPath = `/contract/${row.id}`;

  // Change font color in cells on row hover.
  const newChildren = useMemo(() => {
    if (hovered) {
      return children.map((child: any) => {
        return React.cloneElement(child, {
          className: classes.hoveredCell
        });
      });
    } else {
      return children;
    }
  }, [hovered, children, classes.hoveredCell]);

  return (
      <Table.Row
          className={clsx(
              canEditContract && classes.tableRow,
              hovered && classes.hovered
          )}
          onClick={
            canEditContract
                ? () => {
                  window.open(
                      window.location.origin + ROUTER_BASENAME + contractPath,
                      "_blank"
                  );
                }
                : null
          }
          onMouseEnter={
            canEditContract
                ? () => {
                  setHovered(true);
                }
                : null
          }
          onMouseLeave={
            canEditContract
                ? () => {
                  setHovered(false);
                }
                : null
          }
          {...restProps}
          children={newChildren}
      />
  );
}

export type ContractReportsGridProps = {
  rows: ContractTimeReport[];
  columns: Column[];
  columnExtensions?: Table.ColumnExtension[];
  sorting: Sorting[];
  onSortingChange?: (filters: Sorting[]) => void;
  sortingColumnExtensions?: SortingState.ColumnExtension[];
  filters: Filter[];
  onFiltersChange?: (filters: Filter[]) => void;
  filtersColumnExtensions?: FilteringState.ColumnExtension[];
  filterRowProps?: any;
  grouping?: Grouping[];
  onGroupingChange?: (grouping: Grouping[]) => void;
  defaultGrouping?: Grouping[];
  expandedGroups?: GroupKey[];
  onExpandedGroupsChange?: (expandedGroups: GroupKey[]) => void;
  groupRowProps?: any;
  groupingColumnExtensions?: GroupingState.ColumnExtension[];
  summaryCalculator: (
    type: SummaryType,
    rows: any[],
    getValue: (row: any) => any
  ) => any;
  summaryGroupItems: GroupSummaryItem[];
  showExportButton: boolean;
  exportColumns: Column[];
  exportColumnExtensions?: Table.ColumnExtension[];
};

const ContractReportsGrid: React.FunctionComponent<
  ContractReportsGridProps
> = function(props) {
  const {
    rows,
    columns,
    columnExtensions,
    sorting,
    onSortingChange,
    sortingColumnExtensions,
    filters,
    filterRowProps,
    onFiltersChange,
    filtersColumnExtensions,
    defaultGrouping,
    expandedGroups,
    groupingColumnExtensions,
    groupRowProps,
    onExpandedGroupsChange,
    summaryCalculator,
    summaryGroupItems,
    showExportButton,
    exportColumns,
    exportColumnExtensions
  } = props;
  const exporterRef = useRef(null);

  const startExport = () => {
    //@ts-ignore
    exporterRef.current.exportGrid();
  };

  const onSave = (workbook: any) => {
    workbook.xlsx.writeBuffer().then((buffer: any) => {
      saveAs(
        new Blob([buffer], { type: "application/octet-stream" }),
        "Contract-reports.xlsx"
      );
    });
  };

  const ExportButtonComponent = (props: any) =>
    showExportButton ? ExportButton(props) : <></>;

  /* eslint-disable no-param-reassign */
  const customizeCell = (cell: any, row: any, column: any) => {
    const currentDate = new Date;
    if (new Date(row.lastHoursConfirmationDate) > new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)) {
      if (column.name === 'lastHoursConfirmationDate') {
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '98FB98' } };
        cell.value = "Approved";
      }
    } else {
      if (column.name === 'lastHoursConfirmationDate'){
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'F08080' } };
        cell.value = "Not approved";
      }
    }
  };

  return (
    <Paper>
      <Grid rows={rows} columns={columns}>
        <SortingState
          sorting={sorting}
          onSortingChange={onSortingChange}
          columnExtensions={sortingColumnExtensions}
        />
        <IntegratedSorting />
        <GroupingState
          defaultGrouping={defaultGrouping}
          expandedGroups={expandedGroups}
          columnExtensions={groupingColumnExtensions}
          onExpandedGroupsChange={onExpandedGroupsChange}
        />
        <IntegratedGrouping />
        <FilteringState
          filters={filters}
          onFiltersChange={onFiltersChange}
          columnExtensions={filtersColumnExtensions}
        />
        <IntegratedFiltering />
        <SummaryState groupItems={summaryGroupItems} />
        <IntegratedSummary calculator={summaryCalculator} />
        <Table rowComponent={TableRow} columnExtensions={columnExtensions} />
        <TableGroupRow {...groupRowProps} />
        <TableFilterRow {...filterRowProps} />
        <TableHeaderRow
          showSortingControls
          showGroupingControls
          // @ts-ignore
          sortLabelComponent={SortButton}
          groupButtonComponent={GroupButton}
        />
        <Toolbar />
        <GroupingPanel
          showGroupingControls
          messages={{
            groupByColumn:
              "Click on column group symbol to group by this column"
          }}
        />
        <ExportPanel
          startExport={startExport}
          toggleButtonComponent={ExportButtonComponent}
        />
      </Grid>
      <GridExporter
        ref={exporterRef}
        rows={rows}
        columns={exportColumns}
        sorting={sorting}
        onSave={onSave}
        columnExtensions={exportColumnExtensions}
        customizeCell={customizeCell}
      />
    </Paper>
  );
};

export default ContractReportsGrid;
