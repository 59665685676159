import { combineReducers } from "redux";
import projects from "./projects";
import projectGroups from "./projectGroups";
import contracts from "./contracts";
import currentUser from "./currentUser";
import developers from "./developers";
import timeReports from "./timeReports";
import dailyTimeReports from "./dailyTimeReports";
import timeReportForm from "./timeReportForm";
import accountManagers from "./accountManagers";
import config from "./config";
import contractReports from "./contractReports";
import contractForm from "./contractForm";
import projectForm from "./projectForm";
import general from "./general";

export default combineReducers({
  contracts,
  projects,
  projectGroups,
  currentUser,
  developers,
  timeReports,
  dailyTimeReports,
  timeReportForm,
  accountManagers,
  config,
  contractReports,
  contractForm,
  projectForm,
  general
});
