// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import DailyTimeReports from "views/TimeReports/DailyTimeReports";
import ContractReports from "views/TimeReports/ContractReports";
import Contracts from "views/Contracts/Contracts";
import Projects from "views/Projects/Projects";
import Contract from "views/Contract/Contract";
import Login from "layouts/Login";
import ContractFormPage from "views/Contract/ContractFormPage";
import ProjectForm from "views/Project/ProjectForm";
import TimeReportForm from "./views/TimeReport/TimeReportForm";
import ProjectGroups from "./views/ProjectGroups/ProjectGroups";
import ProjectGroupForm from "./views/ProjectGroups/ProjectGroupForm";

const dashboardRoutes = [
  {
    path: "/",
    name: "Daily Time Reports",
    icon: Dashboard,
    component: DailyTimeReports,
    layout: "/admin",
    exact: true
  },
  {
    path: "/contract-reports",
    name: "Project Reports",
    icon: Dashboard,
    component: ContractReports,
    layout: "/admin",
    exact: true
  },
  {
    path: "/projects",
    name: "Projects",
    icon: Person,
    component: Projects,
    layout: "/admin",
    exact: true
  },
  {
    path: "/projects/:id",
    name: "Project",
    icon: Person,
    component: Contract,
    layout: "/admin",
    hideInMenu: true,
    exact: false
  },
  {
    path: "/project/add",
    name: "Create Project",
    icon: Person,
    component: ProjectForm,
    layout: "/admin",
    hideInMenu: true,
    exact: true
  },
  {
    path: "/project/:id",
    name: "Project",
    icon: Person,
    component: ProjectForm,
    layout: "/admin",
    hideInMenu: true,
    exact: false
  },
  {
    path: "/project-groups",
    name: "Project Groups",
    icon: Person,
    component: ProjectGroups,
    layout: "/admin",
    exact: true
  },
  {
    path: "/project-group/add",
    name: "Create Project Group",
    icon: Person,
    component: ProjectGroupForm,
    layout: "/admin",
    hideInMenu: true,
    exact: true
  },
  {
    path: "/project-group/:id",
    name: "Project Group",
    icon: Person,
    component: ProjectGroupForm,
    layout: "/admin",
    hideInMenu: true,
    exact: false
  },
  {
    path: "/contracts",
    name: "Contracts",
    icon: Person,
    component: Contracts,
    layout: "/admin",
    exact: true
  },
  {
    path: "/contracts/:id",
    name: "Contract",
    icon: Person,
    component: Contract,
    layout: "/admin",
    hideInMenu: true,
    exact: false
  },
  {
    path: "/contract/add",
    name: "Create Contract",
    icon: Person,
    component: ContractFormPage,
    layout: "/admin",
    hideInMenu: true,
    exact: true
  },
  {
    path: "/contract/:id",
    name: "Contract",
    icon: Person,
    component: ContractFormPage,
    layout: "/admin",
    hideInMenu: true,
    exact: false
  },
  {
    path: "/time-report/add",
    name: "Log time",
    icon: Person,
    component: TimeReportForm,
    layout: "/admin",
    hideInMenu: true,
    exact: true
  },
  {
    path: "/time-report/:id",
    name: "Log time",
    icon: Person,
    component: TimeReportForm,
    layout: "/admin",
    hideInMenu: true,
    exact: false
  },
  {
    path: "/login",
    name: "Login",
    icon: Person,
    component: Login,
    layout: "/login",
    hideInMenu: true,
    exact: true
  },
  {
    path: "/logout",
    name: "Logout",
    icon: Person,
    component: () => {
      localStorage.removeItem("token");
      localStorage.removeItem("modal-was-shown");
      window.location.reload();
    },
    layout: "/admin",
    exact: true
  }
];

export default dashboardRoutes;
