import React from "react";
import PropTypes from "prop-types";
import adminTitleStyle from "../../assets/jss/material-dashboard-react/views/adminTitleStyle";
import { makeStyles } from "@material-ui/styles";
import routes from "routes.js";

const useStyles = makeStyles(adminTitleStyle);

function Title(props) {
  const classes = useStyles();
  const { match } = props;
  const activeRoute = routes.find(route => route.path === match.path);
  const title =
    activeRoute !== undefined ? activeRoute.name : "Daily Time Reports";
  return <h4 className={classes.adminTitleWhite}>{title}</h4>;
}

Title.propTypes = {
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default Title;
