import React, { useEffect, useState } from "react";
import { Paper, CircularProgress } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  contractFormRetrieveContract,
  contractFormResetContract,
  contractFormResetRetrieveContractErrors,
  contractFormResetSendContractErrors
} from "redux/actions";
import { makeStyles } from "@material-ui/styles";
import { useIsContractOperationsAllowed } from "../../hooks";
import { RouteComponentProps } from "react-router-dom";
import { ContractFormContract } from "../../types/contract";
import { ContractFormErrors } from "../../redux/types/contractForm";
import ContractForm from "./ContractForm";
import SnackBar from "./SnackBar";
import ErrorMessages from "components/ErrorMessages/ErrorMessages";

const contractFormStyles = () => ({
  contractFormPaper: {
    padding: "10px",
    display: "flex",
    justifyContent: "center"
  }
});

const useStyles = makeStyles(contractFormStyles);

export default function ContractFormPage(
  props: RouteComponentProps<{
    id?: string;
  }>
) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { match, history } = props;

  const contractId =
    match.params.id !== undefined ? parseInt(match.params.id) : 0;
  const isNewContract = contractId === 0;

  const [sendContractAttempt, setSendContractAttempt] = useState<boolean>(
    false
  );
  const [showSnackBar, setShowSnackBar] = useState<boolean>(false);

  const contractOperationsAllowed = useIsContractOperationsAllowed();

  useEffect(() => {
    dispatch(contractFormResetRetrieveContractErrors());
    dispatch(contractFormResetSendContractErrors());
    if (isNewContract) {
      dispatch(contractFormResetContract());
    } else {
      dispatch(contractFormRetrieveContract(contractId));
    }
  }, [contractId, dispatch]);

  // @ts-ignore
  const developersFetching = useSelector(state => state.developers.fetching);
  const currenciesFetching = useSelector(
    // @ts-ignore
    state => state.config.fetchingContractCurrencies
  );
  const contract: ContractFormContract = useSelector(
    // @ts-ignore
    state => state.contractForm.data
  );
  const contractFetching: boolean = useSelector(
    // @ts-ignore
    state => state.contractForm.fetching
  );
  const contractSending: boolean = useSelector(
    // @ts-ignore
    state => state.contractForm.sending
  );
  const retrieveErrors: ContractFormErrors | null = useSelector(
    // @ts-ignore
    state => state.contractForm.retrieveErrors
  );
  const sendErrors: ContractFormErrors | null = useSelector(
    // @ts-ignore
    state => state.contractForm.sendErrors
  );

  if (sendContractAttempt && !contractSending) {
    // If we just created a contract, then we need to redirect on contract form.
    if (isNewContract && contract.id !== 0) {
      history.push(`/contract/${contract.id}`);
    }
    setSendContractAttempt(false);
    setShowSnackBar(sendErrors === null);
  }

  return (
    <div>
      {contractOperationsAllowed ? (
        <Paper className={classes.contractFormPaper}>
          {developersFetching || contractFetching || currenciesFetching ? (
            <CircularProgress />
          ) : retrieveErrors !== null ? (
            <ErrorMessages errors={retrieveErrors} />
          ) : (
            <ContractForm
              contractId={contractId}
              setSendContractAttempt={setSendContractAttempt}
            />
          )}
          <SnackBar
            showSnackBar={showSnackBar}
            setShowSnackBar={setShowSnackBar}
          />
        </Paper>
      ) : (
        "Forbidden"
      )}
    </div>
  );
}
