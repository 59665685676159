import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import {
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  SnackbarContent,
  TextField,
  Theme
} from "@material-ui/core";
import { ProjectStatus } from "../../types/project";
import dataFetcher from "../../services/dataFetcher";
import { makeStyles } from "@material-ui/styles";
import { useIsContractOperationsAllowed } from "../../hooks";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { useDispatch, useSelector } from "react-redux";
import { projectGroupsSetShowToaster } from "../../redux/actions";

type FormData = {
  id: number;
  name: string;
  status: ProjectStatus;
  hasProjects: boolean;
};

type Errors = string[] | { [k in keyof FormData]?: string };

const projectFormStyles = (theme: Theme) => ({
  projectGroupFormPaper: {
    padding: "10px",
    display: "flex",
    justifyContent: "center"
  },
  buttonSpinner: {
    margin: "5px"
  },
  saveButton: {
    marginRight: "5px"
  },
  snackbar: {
    // @ts-ignore
    ...theme.snackBarSuccess,
    // @ts-ignore
    ...theme.snackBar
  },
  snackbarMessage: {
    // @ts-ignore
    ...theme.snackBarMessage
  },
  snackbarIcon: {
    // @ts-ignore
    ...theme.snackbarIcon
  }
});

const UNEXPECTED_ERROR_MSG = "Unrecognized error";

const useStyles = makeStyles(projectFormStyles);

const ProjectGroupForm = (props: RouteComponentProps<{ id?: string }>) => {
  const projectOperationsAllowed = useIsContractOperationsAllowed();
  const classes = useStyles();
  const { match, history } = props;
  const groupId = (match.params.id && parseInt(match.params.id)) || 0;

  const [formData, setFormData] = useState<FormData | null>(null);
  const [errors, setErrors] = useState<Errors | null>(null);
  const [fetching, setFetching] = useState(true);
  const [saving, setSaving] = useState(false);

  const dispatch = useDispatch();
  const showSnackbar = useSelector(
    // @ts-ignore
    state => state.projectGroups.showSnackbar
  );

  useEffect(() => {
    if (groupId === 0) {
      setFormData({
        id: 0,
        name: "",
        status: ProjectStatus.Active,
        hasProjects: false
      });
      setFetching(false);
      return;
    }
    const fetchGroup = async () => {
      try {
        const result = await dataFetcher.projectGroups.getProjectGroup(groupId);
        setFetching(false);
        if (result.data.status === "success") {
          const formData: FormData = result.data.data;
          formData.hasProjects = result.data.data.projects.length > 0;
          setFormData(formData);
          return;
        }
        if (result.data.status === "error" && result.data.data.errors) {
          setErrors(result.data.data.errors);
          return;
        }
        setErrors([UNEXPECTED_ERROR_MSG]);
        console.error("server response", result);
      } catch (error) {
        console.log(error);
        setErrors([UNEXPECTED_ERROR_MSG]);
      }
    };
    fetchGroup().catch(console.error);
  }, [groupId]);

  useEffect(() => {
    if (formData && formData.id !== 0 && groupId === 0) {
      history.push(`/project-group/${formData.id}`);
    }
  }, [formData, groupId, history]);

  const formErrors = [];
  let fieldsErrors: Errors = {};
  if (errors !== null) {
    if (Array.isArray(errors)) {
      for (const error of errors) {
        formErrors.push(<FormLabel error={true}>{error}</FormLabel>);
      }
    } else {
      formErrors.push(
        <FormLabel error={true}>Please fix all errors</FormLabel>
      );
      fieldsErrors = errors;
    }
  }

  const submitForm = async () => {
    if (formData === null) {
      return;
    }
    setSaving(true);
    try {
      let result = null;
      if (groupId === 0) {
        result = await dataFetcher.projectGroups.createProjectGroup(formData);
      } else {
        result = await dataFetcher.projectGroups.updateProjectGroup(
          groupId,
          formData
        );
      }
      setSaving(false);
      if (result.data.status === "success") {
        dispatch(projectGroupsSetShowToaster(true));
        setFormData(result.data.data);
        return;
      }
      if (result.data.status === "error" && result.data.data.errors) {
        setErrors(result.data.data.errors);
        return;
      }
      setErrors([UNEXPECTED_ERROR_MSG]);
      console.error("server response", result);
    } catch (error) {
      console.log(error);
      setErrors([UNEXPECTED_ERROR_MSG]);
    }
  };

  const deleteGroup = async () => {
    setSaving(true);
    try {
      const result = await dataFetcher.projectGroups.deleteProjectGroup(
        groupId
      );
      if (result.data.status === "success") {
        history.push("/project-groups");
      }
      if (result.data.status === "error" && result.data.data.errors) {
        setErrors(result.data.data.errors);
        return;
      }
      setErrors([UNEXPECTED_ERROR_MSG]);
      console.error("server response", result);
    } catch (error) {
      console.log(error);
      setErrors([UNEXPECTED_ERROR_MSG]);
    }
  };

  const showDeleteButton = groupId !== 0 && formData && !formData.hasProjects;

  const form = (
    <form>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            id="name"
            name="name"
            fullWidth
            label="Name"
            value={formData ? formData.name : ""}
            onChange={event => {
              if (formData !== null) {
                setFormData({ ...formData, name: event.target.value });
              }
            }}
            required
            error={Boolean(fieldsErrors.name)}
            helperText={fieldsErrors.name || ""}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <FormControl
                required
                fullWidth
                component={"div"}
                error={Boolean(fieldsErrors.status)}
              >
                <InputLabel htmlFor="status">Status</InputLabel>
                <Select
                  value={formData ? formData.status : ProjectStatus.Active}
                  onChange={event => {
                    if (formData !== null) {
                      setFormData({
                        ...formData,
                        status: event.target.value as ProjectStatus
                      });
                    }
                  }}
                  inputProps={{
                    name: "status",
                    id: "status"
                    // fullWidth: true
                  }}
                >
                  <MenuItem value={ProjectStatus.Active}>Active</MenuItem>
                  <MenuItem value={ProjectStatus.Paused}>Paused</MenuItem>
                  <MenuItem value={ProjectStatus.Closed}>Closed</MenuItem>
                </Select>
                <FormHelperText>{fieldsErrors.status || ""}</FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Button
            type="button"
            variant="contained"
            color="primary"
            disabled={saving}
            className={classes.saveButton}
            onClick={async () => await submitForm()}
          >
            {groupId === 0 ? "Add group" : "Edit group"}
          </Button>
          {showDeleteButton && (
            <Button
              type="button"
              variant="contained"
              color="secondary"
              disabled={saving}
              onClick={async () => await deleteGroup()}
            >
              Delete group
            </Button>
          )}
        </Grid>
      </Grid>
    </form>
  );

  const snackBar = (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left"
      }}
      open={showSnackbar}
      onClose={(event, reason) => {
        if (reason === "timeout") {
          dispatch(projectGroupsSetShowToaster(false));
        }
      }}
      autoHideDuration={2000}
    >
      <SnackbarContent
        aria-describedby="snackbar-message"
        className={classes.snackbar}
        message={
          <span id="snackbar-message" className={classes.snackbarMessage}>
            <CheckCircleIcon className={classes.snackbarIcon} />
            Group saved!
          </span>
        }
      />
    </Snackbar>
  );

  return (
    <div>
      {projectOperationsAllowed ? (
        <Paper className={classes.projectGroupFormPaper}>
          {fetching && <CircularProgress />}
          {formErrors.length > 0 && formErrors}
          {!fetching && formData !== null && form}
          {snackBar}
        </Paper>
      ) : (
        "Forbidden"
      )}
    </div>
  );
};

export default ProjectGroupForm;
