import {
  PROJECT_FORM_RESET_PROJECT,
  PROJECT_FORM_RESET_RETRIEVE_PROJECT_ERRORS,
  PROJECT_FORM_RESET_SEND_PROJECT_ERRORS,
  PROJECT_FORM_RETRIEVE_PROJECT,
  PROJECT_FORM_SEND_PROJECT,
  PROJECT_FORM_SET_PROJECT,
  PROJECT_FORM_SET_RETRIEVE_PROJECT_ERRORS,
  PROJECT_FORM_SET_SEND_PROJECT_ERRORS,
  ProjectFormActionTypes,
  ProjectFormState
} from "../types/projectForm";
import {
  ProjectStatus,
} from "../../types/project";

const initialState: ProjectFormState = {
  data: {
    id: 0,
    name: "",
    account_manager_id: null,
    client: "",
    status: ProjectStatus.Active,
    project_group_id: null
  },
  fetching: false,
  sending: false,
  retrieveErrors: null,
  sendErrors: null,
};

export default function(
  state: ProjectFormState = initialState,
  action: ProjectFormActionTypes
): ProjectFormState {
  switch (action.type) {
    case PROJECT_FORM_SET_PROJECT:
      return { ...state, data: action.payload, fetching: false, sending: false };
    case PROJECT_FORM_RETRIEVE_PROJECT:
      return { ...state, fetching: true, retrieveErrors: null };
    case PROJECT_FORM_SEND_PROJECT:
      return { ...state, sending: true, sendErrors: null };
    case PROJECT_FORM_SET_RETRIEVE_PROJECT_ERRORS:
      return {
        ...state,
        retrieveErrors: Array.isArray(action.payload)
          ? [...action.payload]
          : { ...action.payload },
        sending: false,
        fetching: false
      };
    case PROJECT_FORM_SET_SEND_PROJECT_ERRORS:
      return {
        ...state,
        sendErrors: Array.isArray(action.payload)
          ? [...action.payload]
          : { ...action.payload },
        sending: false,
        fetching: false
      };
    case PROJECT_FORM_RESET_PROJECT:
      return { ...state, data: { ...initialState.data } };
    case PROJECT_FORM_RESET_RETRIEVE_PROJECT_ERRORS:
      return { ...state, retrieveErrors: null };
    case PROJECT_FORM_RESET_SEND_PROJECT_ERRORS:
      return { ...state, sendErrors: null };
    default:
      return state;
  }
}
