import { ProjectFormProject } from "../../types/project";

export const PROJECT_FORM_RETRIEVE_PROJECT = "PROJECT_FORM_RETRIEVE_PROJECT";
export const PROJECT_FORM_SET_PROJECT = "PROJECT_FORM_SET_PROJECT";
export const PROJECT_FORM_SEND_PROJECT = "PROJECT_FORM_SEND_PROJECT";
export const PROJECT_FORM_SET_RETRIEVE_PROJECT_ERRORS = "PROJECT_FORM_SET_RETRIEVE_PROJECT_ERRORS";
export const PROJECT_FORM_SET_SEND_PROJECT_ERRORS = "PROJECT_FORM_SET_SEND_PROJECT_ERRORS";
export const PROJECT_FORM_RESET_PROJECT = "PROJECT_FORM_RESET_PROJECT";
export const PROJECT_FORM_RESET_RETRIEVE_PROJECT_ERRORS = "PROJECT_FORM_RESET_RETRIEVE_PROJECT_ERRORS";
export const PROJECT_FORM_RESET_SEND_PROJECT_ERRORS = "PROJECT_FORM_RESET_SEND_PROJECT_ERRORS";
export const PROJECT_FORM_RETRIEVE_TOGGL_WORKSPACES = "PROJECT_FORM_TOGGL_RETRIEVE_WORKSPACES";
export const PROJECT_FORM_RETRIEVE_TOGGL_PROJECTS = "PROJECT_FORM_TOGGL_RETRIEVE_PROJECTS";
export const PROJECT_FORM_SET_TOGGL_WORKSPACES = "PROJECT_FORM_TOGGL_SET_WORKSPACES";
export const PROJECT_FORM_SET_TOGGL_PROJECTS = "PROJECT_FORM_TOGGL_SET_PROJECTS";
export const PROJECT_FORM_SET_NO_TOGGL_KEY = "PROJECT_FORM_TOGGL_SET_NO_KEY";

type ProjectFormRetrieveProjectAction = {
  type: typeof PROJECT_FORM_RETRIEVE_PROJECT;
  payload: number;  
};

type ProjectFormSetProjectAction = {
  type: typeof PROJECT_FORM_SET_PROJECT;
  payload: ProjectFormProject;
};

type ProjectFormSendProjectAction = {
  type: typeof PROJECT_FORM_SEND_PROJECT;
  payload: ProjectFormProject;
};

type ProjectFormSetRetrieveProjectErrorsAction = {
  type: typeof PROJECT_FORM_SET_RETRIEVE_PROJECT_ERRORS;
  payload: Errors;
};

type ProjectFormSetSendProjectErrorsAction = {
  type: typeof PROJECT_FORM_SET_SEND_PROJECT_ERRORS;
  payload: Errors;
};

type ProjectFormResetProjectAction = {
  type: typeof PROJECT_FORM_RESET_PROJECT;
};

type ProjectFormResetRetrieveProjectErrorsAction = {
  type: typeof PROJECT_FORM_RESET_RETRIEVE_PROJECT_ERRORS;
};

type ProjectFormResetSendProjectErrorsAction = {
  type: typeof PROJECT_FORM_RESET_SEND_PROJECT_ERRORS;
};

export type ProjectFormActionTypes =
  | ProjectFormRetrieveProjectAction
  | ProjectFormSetProjectAction
  | ProjectFormSendProjectAction
  | ProjectFormSetRetrieveProjectErrorsAction
  | ProjectFormSetSendProjectErrorsAction
  | ProjectFormResetProjectAction
  | ProjectFormResetRetrieveProjectErrorsAction
  | ProjectFormResetSendProjectErrorsAction;

export type Errors = string[] | { [k in keyof ProjectFormProject]?: string };

export type ProjectFormState = {
  data: ProjectFormProject;
  fetching: boolean;
  sending: boolean;
  retrieveErrors: Errors | null;
  sendErrors: Errors | null;
};
