export const ROUTER_BASENAME = "/dashboard";

// @TODO get rid of all these strange constants
export const ACCOUNT_MANAGERS = [
  { uid: 440, name: "Виктория Шадуро" },
  { uid: 697, name: "Жанна Дятлова" },
  { uid: 313, name: "Андрей Скрынько" }
];

export const PROJECT_STATUSES = [
  { id: "active", label: "Active" },
  { id: "paused", label: "Paused" },
  { id: "closed", label: "Closed" }
];

export const CONTRACT_STATUSES = [
  { id: "active", label: "Active" },
  { id: "paused", label: "Paused" },
  { id: "closed", label: "Closed" }
];


export const CONTRACT_TYPES = [
  { id: "hourly", label: "Hourly" },
  { id: "subscription", label: "Subscription" },
  { id: "fixed", label: "Fixed Price" }
];

export const CONTRACT_PROVIDERS = [
  { id: "upwork", label: "Upwork" },
  { id: "dotwrk", label: ".wrk" },
  { id: "toptal", label: "Toptal" },
  { id: "toggl", label: "Toggl" },
  { id: "clockify", label: "Clockify" }
];

export const CONTRACT_POSITIONS = [
  { id: "junior_software_engineer", label: "Младший инженер-программист" },
  { id: "software_engineer", label: "Инженер-программист" },
  { id: "technical_supervisor", label: "Технический руководитель" },
  { id: "test_engineer", label: "Инженер-тестировщик" },
  { id: "project_manager", label: "Менеджер проектов" }
];

export const DATE_FORMAT = "YYYY-MM-DD";
export const DATETIME_FORMAT = "YYYY-MM-DD kk:mm:ss";
export const DATE_FORMAT_DAILY_TIME_REPORTS = "YYYY-MM-DD, dddd";
