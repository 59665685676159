import {
  CONFIG_RETRIEVE_CONTRACT_CURRENCIES,
  CONFIG_SET_CONTRACT_CURRENCIES
} from "../actionTypes";

const initialState = {
  contractCurrencies: {},
  fetchingContractCurrencies: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case CONFIG_SET_CONTRACT_CURRENCIES:
      return {
        ...state,
        contractCurrencies: { ...action.payload.currencies },
        fetchingContractCurrencies: false
      };
    case CONFIG_RETRIEVE_CONTRACT_CURRENCIES:
      return { ...state, fetchingContractCurrencies: true };
    default:
      return state;
  }
}
