export const GENERAL_RETRIEVE_CLIENT_NAMES = "GENERAL_RETRIEVE_CLIENT_NAMES";
export const GENERAL_SET_CLIENT_NAMES = "GENERAL_SET_CLIENT_NAMES";

type generalRetrieveClientNames = {
  type: typeof GENERAL_RETRIEVE_CLIENT_NAMES;
};

type generalSetClientNames = {
  type: typeof GENERAL_SET_CLIENT_NAMES;
  payload: string[];
};

export type generalActionTypes =
  | generalRetrieveClientNames
  | generalSetClientNames;

export type GeneralState = {
  clientNames: string[];
  fetchingClientNames: boolean;
};
