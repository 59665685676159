import {
  RETRIEVE_ACCOUNT_MANAGERS,
  SET_ACCOUNT_MANAGERS,
  AccountManagersActionTypes
} from "../types/accountManagers";
import { SimpleUser } from "../../types/user";

export function retrieveAccountManagers(): AccountManagersActionTypes {
  return {
    type: RETRIEVE_ACCOUNT_MANAGERS
  };
}

export function setAccountManagers(
  managers: SimpleUser[]
): AccountManagersActionTypes {
  return {
    type: SET_ACCOUNT_MANAGERS,
    payload: managers
  };
}
