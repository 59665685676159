import React, { useEffect, useState } from "react";
import {
  Switch,
  Route,
  withRouter,
  RouteComponentProps
} from "react-router-dom";
// @material-ui/core components
// core components
import Footer from "components/Footer/Footer.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";

import routes from "routes.js";
import { Role } from "types/user";
import dashboardStyle from "assets/jss/material-dashboard-react/layouts/dashboardStyle.jsx";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import NotificationsIcon from "@material-ui/icons/Notifications";
import Badge from "@material-ui/core/Badge";

import {
  Box,
  Typography,
  CssBaseline,
  CircularProgress,
  DialogContent,
  DialogTitle,
  IconButton
} from "@material-ui/core";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { retrieveCurrentUser } from "redux/actions";
import { makeStyles } from "@material-ui/styles";
import GridContainer from "../components/Grid/GridContainer";
import GridItem from "../components/Grid/GridItem";
import CardHeader from "../components/Card/CardHeader";
import CardBody from "../components/Card/CardBody";
import Title from "./Admin/Title";
import { User } from "../types/user";
import { contractsRetrieveInactiveContracts } from "redux/actions";
import Modal from "components/Modal/Modal";

// @ts-ignore
const useStyles = makeStyles(dashboardStyle);

const switchRoutes = (
  <Switch>
    {routes.map((route, key) => {
      if (route.layout === "/admin") {
        return <Route exact path={route.path} component={route.component} key={key}/>;
      }
    })}
  </Switch>
);

const switchRouteTitles = (
  <Switch>
    {routes.map((route, key) => {
      if (route.layout === "/admin") {
        return <Route exact path={route.path} component={Title} key={key}/>;
      }
    })}
  </Switch>
);

const AdminLayout: React.FunctionComponent<RouteComponentProps<{}>> = function(
  props: RouteComponentProps<{}>
) {
  const dispatch = useDispatch();
  // @ts-ignore
  const currentUser: User | null = useSelector(
    (state: RootStateOrAny) => state.currentUser.user
  );

  const inactiveContracts = useSelector(
    (state: RootStateOrAny) => state.contracts.inactive
  );

  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    dispatch(retrieveCurrentUser());
  }, [dispatch]);

  useEffect(() => {
    if (
      inactiveContracts.length > 0 &&
      localStorage.getItem("modal-was-shown") === "false"
    ) {
      setTimeout(function() {
        setOpenModal(true);
        localStorage.removeItem("modal-was-shown");
        localStorage.setItem("modal-was-shown", "true");
      }, 5000);
    }
    if (
      currentUser &&
      inactiveContracts.length === 0 &&
      currentUser.roles.includes(Role.StaffingManager)
    ) {
      dispatch(contractsRetrieveInactiveContracts());
    }
  }, [currentUser, inactiveContracts, dispatch]);

  const classes = useStyles();
  return currentUser !== null ? (
    <div className={classes.wrapper}>
      <Modal isOpen={openModal} setIsOpen={setOpenModal}>
        <DialogTitle id="alert-dialog-slide-title">
          It seems that the work on this contract is completed. Please follow
          the link to make it inactive:
        </DialogTitle>
        <DialogContent>
          <div className={classes.list}>
            {inactiveContracts.map((contract: any) => {
              return (
                <a
                  key={contract.id}
                  href={`${process.env.REACT_APP_PATH}/dashboard/contract/${contract.id}`}
                  target="_blank"
                  className={classes.link}
                >
                  {contract.name}
                </a>
              );
            })}
          </div>
        </DialogContent>
      </Modal>
      <CssBaseline />
      <Sidebar location={props.location} />
      <div className={classes.mainPanel}>
        <div className={classes.header}>
          <Typography variant="h5">
            You are logged in as <i>{currentUser.name}</i>
          </Typography>
          {currentUser.roles.includes(Role.StaffingManager) ? (
            <IconButton onClick={() => setOpenModal(true)}>
              <Badge
                color="secondary"
                overlap="circle"
                variant="dot"
                invisible={inactiveContracts.length === 0}
                classes={{
                  badge: classes.badge
                }}
              >
                <NotificationsNoneIcon fontSize="large" />
              </Badge>
            </IconButton>
          ) : (
            <></>
          )}
        </div>
        <div className={classes.content}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <CardHeader color="warning">{switchRouteTitles}</CardHeader>
              <CardBody>
                <div className={classes.container}>{switchRoutes}</div>
              </CardBody>
            </GridItem>
          </GridContainer>
        </div>
        <Footer />
      </div>
    </div>
  ) : (
    <Box
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh"
      }}
    >
      <CircularProgress className={classes.progress} />
    </Box>
  );
};

export default withRouter<
  RouteComponentProps<{}>,
  React.FunctionComponent<RouteComponentProps<{}>>
>(AdminLayout);
