import React from "react";
import { Button, Dialog, DialogActions, Slide } from "@material-ui/core";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

function Modal({ ...props }) {
  const { isOpen, setIsOpen } = props;

  function handleClose() {
    setIsOpen(false);
  }

  return (
    <Dialog
      open={isOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      {props.children}
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default Modal;
