import * as actionTypes from "../actionTypes";

export const dailyTimeReportsRetrieveTimeReports = () => ({
  type: actionTypes.DAILY_TIME_REPORTS_RETRIEVE_TIME_REPORTS,
  payload: {}
});

export const dailyTimeReportsSetTimeReports = timeReports => ({
  type: actionTypes.DAILY_TIME_REPORTS_SET_TIME_REPORTS,
  payload: { timeReports }
});

export const dailyTimeReportsSetParams = params => ({
  type: actionTypes.DAILY_TIME_REPORTS_SET_PARAMS,
  payload: { params }
});

export const dailyTimeReportsSetFilters = filters => ({
  type: actionTypes.DAILY_TIME_REPORTS_SET_FILTERS,
  payload: { filters }
});

export const dailyTimeReportsSetSorting = sorting => ({
  type: actionTypes.DAILY_TIME_REPORTS_SET_SORTING,
  payload: { sorting }
});

export const dailyTimeReportsSetPager = pager => ({
  type: actionTypes.DAILY_TIME_REPORTS_SET_PAGER,
  payload: { pager }
});

export const dailyTimeReportsResetParams = params => ({
  type: actionTypes.DAILY_TIME_REPORTS_RESET_PARAMS,
  payload: { params }
});

export const dailyTimeReportsResetFilters = filters => ({
  type: actionTypes.DAILY_TIME_REPORTS_RESET_FILTERS,
  payload: { filters }
});

export const dailyTimeReportsResetSorting = sorting => ({
  type: actionTypes.DAILY_TIME_REPORTS_RESET_SORTING,
  payload: { sorting }
});

export const dailyTimeReportsResetPager = () => ({
  type: actionTypes.DAILY_TIME_REPORTS_RESET_PAGER,
  payload: {}
});
