import {
  RETRIEVE_CURRENT_USER,
  SET_CURRENT_USER,
  CurrentUserActionTypes
} from "../types/currentUser";
import { User } from "../../types/user";

export function retrieveCurrentUser(): CurrentUserActionTypes {
  return {
    type: RETRIEVE_CURRENT_USER
  };
}

export function setCurrentUser(user: User): CurrentUserActionTypes {
  return {
    type: SET_CURRENT_USER,
    payload: user
  };
}
