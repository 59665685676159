import React from "react";
import {
  Grid,
  FormHelperText,
  IconButton,
  Button,
  FormControl
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { useDispatch } from "react-redux";
import { contractFormSetContract } from "redux/actions";
import moment from "moment";
import { DATETIME_FORMAT } from "../../variables/general";
import { ContractFormContract, ContractStatus } from "../../types/contract";
import { ContractFormErrors } from "../../redux/types/contractForm";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { DateTimePicker } from "components/DateTimePicker/DateTimePicker";
import { getCopyOfObject } from "helpers";

interface DateRangePickerProps {
  contract: ContractFormContract;
  isBeingEdited: boolean;
  fieldsErrors: ContractFormErrors;
}

export function DateRangePicker({
  contract,
  isBeingEdited,
  fieldsErrors
}: DateRangePickerProps) {
  const dispatch = useDispatch();

  const setTimeInterval = (
    date: MaterialUiPickersDate,
    timeIntervalIndex: number,
    key: "start_date" | "end_date"
  ) => {
    if (date !== null) {
      const contractCopy = getCopyOfObject(contract);
      contractCopy.time_intervals[timeIntervalIndex][key] = moment(date).format(
        DATETIME_FORMAT
      );

      dispatch(contractFormSetContract(contractCopy));
    }
  };

  const addEmptyTimeInterval = () => {
    const contractCopy = getCopyOfObject(contract);
    contractCopy.time_intervals.push({
      start_date: null,
      end_date: null
    });

    dispatch(contractFormSetContract(contractCopy));
  };

  const deleteTimeInterval = (intervalIndex: number) => {
    const contractCopy = getCopyOfObject(contract);
    const filterendTimeIntervals = contract.time_intervals.filter(
      (interval, index) => index !== intervalIndex
    );
    contractCopy.time_intervals = filterendTimeIntervals;

    dispatch(contractFormSetContract(contractCopy));
  };

  return (
    <FormControl error={fieldsErrors.time_intervals !== undefined}>
      {!!contract.time_intervals &&
        contract.time_intervals.map((timeInterval, timeIntervalIndex) => (
          <Grid
            key={timeInterval.id}
            container
            spacing={2}
            style={{ marginBottom: "5px" }}
          >
            <Grid item>
              <DateTimePicker
                id="start-date-picker"
                label="Start date"
                disabled={!isBeingEdited}
                value={timeInterval.start_date}
                onChange={(date: MaterialUiPickersDate) => {
                  setTimeInterval(date, timeIntervalIndex, "start_date");
                }}
              />
            </Grid>
            <Grid item>
              <DateTimePicker
                id="end-date-picker"
                label="End date"
                disabled={
                  (timeIntervalIndex + 1 === contract.time_intervals.length &&
                    contract.status === ContractStatus.Active) ||
                  !isBeingEdited
                }
                value={timeInterval.end_date}
                onChange={(date: MaterialUiPickersDate) => {
                  setTimeInterval(date, timeIntervalIndex, "end_date");
                }}
              />
            </Grid>
            <Grid item>
              {contract.time_intervals.length > 1 && (
                <IconButton
                  disabled={!isBeingEdited}
                  onClick={() => {
                    deleteTimeInterval(timeIntervalIndex);
                  }}
                >
                  <DeleteIcon color={isBeingEdited ? "primary" : "disabled"} />
                </IconButton>
              )}
            </Grid>
          </Grid>
        ))}
      <Grid>
        <Button
          disabled={!isBeingEdited}
          type="button"
          variant="outlined"
          color="primary"
          onClick={addEmptyTimeInterval}
        >
          Add time interval
        </Button>
      </Grid>
      <FormHelperText>{fieldsErrors.time_intervals || ""}</FormHelperText>
    </FormControl>
  );
}
