import { ProjectGroup } from "../../types/projectGroup";

export const PROJECT_GROUPS_RETRIEVE_GROUPS = "PROJECT_GROUPS_RETRIEVE_GROUPS";
export const PROJECT_GROUPS_SET_GROUPS = "PROJECT_GROUPS_SET_GROUPS";
export const PROJECT_GROUPS_SET_SHOW_SNACKBAR = "PROJECT_GROUPS_SET_SHOW_SNACKBAR";

type ProjectGroupsRetrieveGroupsAction = {
  type: typeof PROJECT_GROUPS_RETRIEVE_GROUPS;
};

type ProjectGroupsSetGroupsAction = {
  type: typeof PROJECT_GROUPS_SET_GROUPS;
  payload: ProjectGroup[];
};

type ProjectGroupsSetShowSnackbarAction = {
  type: typeof PROJECT_GROUPS_SET_SHOW_SNACKBAR;
  payload: boolean;
};

export type ProjectGroupsActionTypes =
  | ProjectGroupsRetrieveGroupsAction
  | ProjectGroupsSetGroupsAction
  | ProjectGroupsSetShowSnackbarAction;

export type ProjectGroupsState = {
  fetching: boolean;
  data: ProjectGroup[];
  initialized: boolean;
  showSnackbar: boolean;
};
