import * as actionTypes from "../actionTypes";

export const contractsRetrieveContracts = () => ({
  type: actionTypes.CONTRACTS_RETRIEVE_CONTRACTS,
  payload: {}
});

export const contractsSetContracts = contracts => ({
  type: actionTypes.CONTRACTS_SET_CONTRACTS,
  payload: { contracts }
});

export const contractsRetrieveInactiveContracts = () => ({
  type: actionTypes.CONTRACTS_RETRIEVE_INACTIVE_CONTRACTS,
  payload: {}
});

export const contractsSetInactiveContracts = inactive => ({
  type: actionTypes.CONTRACTS_SET_INACTIVE_CONTRACTS,
  payload: { inactive }
});

export const contractsSetParams = params => ({
  type: actionTypes.CONTRACTS_SET_PARAMS,
  payload: { params }
});

export const contractsSetFilters = filters => ({
  type: actionTypes.CONTRACTS_SET_FILTERS,
  payload: { filters }
});

export const contractsSetSorting = sorting => ({
  type: actionTypes.CONTRACTS_SET_SORTING,
  payload: { sorting }
});

export const contractsSetGrouping = grouping => ({
  type: actionTypes.CONTRACTS_SET_GROUPING,
  payload: { grouping }
});

export const contractsResetParams = () => ({
  type: actionTypes.CONTRACTS_RESET_PARAMS,
  payload: {}
});

export const contractsResetFilters = () => ({
  type: actionTypes.CONTRACTS_RESET_FILTERS,
  payload: {}
});

export const contractsResetSorting = () => ({
  type: actionTypes.CONTRACTS_RESET_SORTING,
  payload: {}
});

export const contractsResetGrouping = () => ({
  type: actionTypes.CONTRACTS_RESET_GROUPING,
  payload: {}
});
