// Check whether given string value is correct decimal number or not.
import moment from "moment";

export const isCorrectPositiveDecimal = (value, maxDecimals = 2) => {
  let isDecimal = false;
  if (typeof value === "string" && !isNaN(+value)) {
    const dotIndex = value.lastIndexOf(".");
    if (
      (dotIndex === -1 || value.length - dotIndex - 1 <= maxDecimals) &&
      value.indexOf("+") === -1 &&
      value.indexOf("-") === -1
    ) {
      isDecimal = true;
    }
  }
  return isDecimal;
};

export const isInteger = value => {
  return (
    !isNaN(value) &&
    parseInt(Number(value)) == value &&
    !isNaN(parseInt(value, 10))
  );
};
export const isCorrectMinutes = value => {
  return isInteger(value) && parseInt(value) > 0 && parseInt(value) < 60;
}
// Returns date of current week Monday excluding.
export const getMondayDate = () => {
  let mondayDate = moment().day(1);
  if (moment().day() === 0) {
    // If today is Sunday, then moment.js considers that new week started.
    // So, we should set it directly to be Monday of this week.
    mondayDate = moment().day(-6);
  } else if (moment().day() === 1) {
    // If today is Monday, we set it to be a Monday of prev week.
    mondayDate = moment().day(-6);
  }
  return mondayDate;
};

export const getCopyOfObject = obj => JSON.parse(JSON.stringify(obj));
