export enum ContractStatus {
  Active = "active",
  Paused = "paused",
  Closed = "closed"
}

export enum ContractType {
  Hourly = "hourly",
  Subscription = "subscription",
  Fixed = "fixed"
}

export enum ContractProvider {
  Upwork = "upwork",
  Dotwrk = "dotwrk",
  Toptal = "toptal",
  Toggl = "toggl",
  Clockify = "clockify"
}

export enum ContractPosition {
  JUNIOR_SOFTWARE_ENGINEER = "Младший инженер-программист",
  SOFTWARE_ENGINEER = "Инженер-программист",
  TECHNICAL_SUPERVISOR = "Технический руководитель",
  TEST_ENGINEER = "Инженер-тестировщик",
  PROJECT_MANAGER = "Менеджер проектов"
}

export type ContractTimeInterval = {
  id: number;
  start_date: string;
  end_date: string | null;
}

export type ContractFormContract = {
  id: number;
  name: string;
  developer_id: number | null;
  project_id: any;
  time_intervals: ContractTimeInterval[];
  status: ContractStatus;
  type: ContractType;
  hourly_rate: string;
  provider: ContractProvider;
  contract_amount: string;
  daily_workload: string;
  toggl_workspace_id: number | null;
  toggl_project_id: number | null;
  clockify_workspace_id: number | null;
  clockify_project_id: number | null;
  currency_code: string;
  position: ContractPosition | null;
};
