import * as actionTypes from "../actionTypes";

export const retrieveDevelopers = () => ({
  type: actionTypes.RETRIEVE_DEVELOPERS,
  payload: {}
});

export const setDevelopers = developers => ({
  type: actionTypes.SET_DEVELOPERS,
  payload: {    developers
  }
});
