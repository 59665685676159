import React, {useEffect, useMemo, useState} from "react";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import {
  FilteringState,
  IntegratedFiltering,
  IntegratedSorting,
  PagingState,
  SortingState,
  CustomPaging,
  GroupingState,
  IntegratedGrouping,
  SummaryState,
  IntegratedSummary,
  SelectionState,
  IntegratedSelection
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
  TableFilterRow,
  PagingPanel,
  GroupingPanel,
  TableGroupRow,
  Toolbar,
  TableSelection
} from "@devexpress/dx-react-grid-material-ui";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import { useIsTimeReportOperationsAllowed } from "../../hooks";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { ROUTER_BASENAME } from "../../variables/general";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  Snackbar,
} from "@material-ui/core";
import dataFetcher from "../../services/dataFetcher";
import SortButton from "../CustomButtons/SortButton";
import GroupButton from "../CustomButtons/GroupButton";

const tableRowStyles = theme => ({
  tableRow: {
    cursor: "pointer"
  },
  hovered: {
    backgroundColor: theme.palette.primary.main
  },
  hoveredCell: {
    color: "white"
  }
});

const contractFormStyles = ({
  snackbar: {
    backgroundColor: '#ffa726',
  }
});
const useStyles = makeStyles(contractFormStyles);
const useTableRowStyles = makeStyles(tableRowStyles);

function TableRow({ row, children, ...restProps }) {
  const history = useHistory();
  const classes = useTableRowStyles();
  const [renderRedirect, setRenderRedirect] = useState(false);
  const [hovered, setHovered] = useState(false);

  const user = useSelector(state => state.currentUser.user);

  const reportPath = row.id !== undefined ? `/time-report/${row.id}` : '/time-report/add';
  const canEditTimeReport =
    (useIsTimeReportOperationsAllowed() || user.uid === row.developer_uid) &&
    reportPath !== "";
  // Change font color in cells on row hover.
  const newChildren = useMemo(() => {
    if (hovered) {
      return children.map(child => {
        return React.cloneElement(child, {
          className: classes.hoveredCell
        });
      });
    } else {
      return children;
    }
  }, [hovered, children, classes.hoveredCell]);

  // Cannot use router Link as wrapper because it breaks all row styles.
  if (renderRedirect) {
    history.push({
      pathname: reportPath,
      search: '?devName=' + row.developer_name +
          '&contract=' + row.contract_name +
          '&date=' + row.day_worked_on.substr(0, 10)
    });
  }

  return (
    <Table.Row
      className={clsx(
        canEditTimeReport && classes.tableRow,
        hovered && classes.hovered
      )}
      onMouseDown={
        canEditTimeReport
          ? event => {
              if (event.target.type !== 'checkbox') {
                if (event.button === 0) {
                  setRenderRedirect(true);
                } else if (event.button === 1) {
                  window.open(
                      window.location.origin + ROUTER_BASENAME + reportPath,
                      "_blank"
                  );
                }
              }
            }
          : null
      }
      onMouseEnter={
        canEditTimeReport
          ? event => {
              setHovered(true);
            }
          : null
      }
      onMouseLeave={
        canEditTimeReport
          ? event => {
              setHovered(false);
            }
          : null
      }
      {...restProps}
      children={newChildren}
    />
  );
}

function TableContainer(props) {
  const useStyles = makeStyles({
    customTableContainer: {
      overflow: "initial"
    }
  })
  const classes = useStyles();
  return(
      <Table.Container
          classes={{root: classes.customTableContainer}}
          className={"tableContainer"}
          {...props}
      />
  )
}

function GridRoot(props) {
  return( <Grid.Root className={"gridContainer"} {...props}/> )
}

function DailyTimeReportsGrid(props) {
  const [openDeleteReportDialog, setOpenDeleteReportDialog] = useState(false);
  const timeReports = useSelector(state => state.dailyTimeReports.list);
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [dataCopyId, setDataCopyId] = useState(0);
  const classes = useStyles();

  const deleteReportDialog = (
      <Dialog
          open={openDeleteReportDialog}
          onClose={handleCloseDeleteReportDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure that you want to delete these Time Reports?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDeleteTimeReport(timeReports)} color="primary">
            Yes
          </Button>
          <Button
              onClick={handleCloseDeleteReportDialog}
              color="secondary"
              autoFocus
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
  );

  const snackBar = (
      <Snackbar
          ContentProps={{
            classes: {
              root: classes.snackbar
            }
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          color={"secondary"}
          open={showSnackBar}
          message={"Time reports were deleted"}
          onClose={(event, reason) => {
            if (reason === "timeout") {
              setShowSnackBar(false);
            }
          }}
          autoHideDuration={8000}
          action={
            <Button
              variant="outlined"
              color="inherit"
              size="small"
              onClick={event => {
                dataFetcher.timeReports.restoreTimeReports(dataCopyId)
                setTableRows(tableRowsCopy);
                event.preventDefault();
              }}
            >
              Cancel
            </Button>
          }
      >
      </Snackbar>
  );

  const [tableRows, setTableRows] = useState([])
  const [tableRowsCopy, setTableRowsCopy] = useState([])
  useEffect(() => {
    setTableRows(props.rows);
  },[props.rows]);

  const [selection, setSelection] = useState([]);
  const {
    columns,
    onSortingChange,
    sorting,
    sortingColumnExtensions,
    filters,
    onFiltersChange,
    filtersColumnExtensions,
    defaultGrouping,
    groupingColumnExtensions,
    groupRowProps,
    expandedGroups,
    onExpandedGroupsChange,
    summaryGroupItems,
    totalGroupItems,
    summaryCalculator,
    pager,
    onCurrentPageChange
  } = props;
  return (
    <Paper>
      <Grid rows={tableRows} columns={columns} rootComponent={GridRoot}>
        <SelectionState
            selection={selection}
            onSelectionChange={setSelection}
        />
        <IntegratedSelection />
        <SortingState
          sorting={sorting}
          onSortingChange={onSortingChange}
          columnExtensions={sortingColumnExtensions}
        />
        <IntegratedSorting />
        <FilteringState
          filters={filters}
          onFiltersChange={onFiltersChange}
          columnExtensions={filtersColumnExtensions}
        />
        <IntegratedFiltering />
        <GroupingState
          columnExtensions={groupingColumnExtensions}
          defaultGrouping={defaultGrouping}
          expandedGroups={expandedGroups}
          onExpandedGroupsChange={onExpandedGroupsChange}
        />
        <IntegratedGrouping />
        <PagingState
          currentPage={pager.currentPage - 1}
          pageSize={
            pager.daysTotal > pager.daysPerPage
              ? pager.daysPerPage
              : pager.daysTotal
          }
          onCurrentPageChange={onCurrentPageChange}
        />
        <CustomPaging totalCount={pager.daysTotal} />
        <Table containerComponent={TableContainer} rowComponent={TableRow} />
        <TableSelection
          showSelectionColumn={useIsTimeReportOperationsAllowed()}
        />
        <Toolbar />
        <SummaryState
          groupItems={summaryGroupItems}
          totalItems={totalGroupItems}
        />
        <IntegratedSummary calculator={summaryCalculator} />
        <GroupingPanel
          containerComponent={PanelWithBulkButton}
          showGroupingControls
          emptyMessageComponent={() =>
            "Click on column group symbol to group by this column"
          }
        />
        <TableGroupRow {...groupRowProps} />
        <TableFilterRow />
        <PagingPanel />
        <TableHeaderRow
          showSortingControls
          showGroupingControls
          sortLabelComponent={SortButton}
          groupButtonComponent={GroupButton}
        />
      </Grid>
      {snackBar}
      {deleteReportDialog}
    </Paper>
  );

  function handleDeleteTimeReport(timeReports) {
    setOpenDeleteReportDialog(false);
    deleteSelectedReports(selection, timeReports)
    setShowSnackBar(true);
  }

  function handleCloseDeleteReportDialog() {
    setOpenDeleteReportDialog(false);
  }

  function PanelWithBulkButton(props) {
    if (useIsTimeReportOperationsAllowed()) {
      props.children.unshift(
          <IconButton>
            <DeleteIcon
                color="primary"
                onClick={event => {
                  if (selection.length !== 0) {
                    setOpenDeleteReportDialog(true);
                  }
                  event.preventDefault();
                }}
            />
          </IconButton>)
    }

    return <GroupingPanel.Container className={"gridToolbar"}  {...props}/>
  }

  function deleteSelectedReports(selected, timeReports) {
    if (selection !== undefined) {
      setTableRowsCopy(tableRows)
      let rows = [...tableRows];
      let ids_to_delete = [];
      selection.forEach(function (rowId) {
        ids_to_delete.push(timeReports[rowId].id);
        let row = {...tableRows[rowId]};
        row.hours = 0;
        rows[rowId] = row;
      })
      dataFetcher.timeReports.deleteTimeReports(ids_to_delete).then(r => {
        setDataCopyId(r.data);
      })
      setTableRows(rows);
    }
    setSelection([])
  }
}

DailyTimeReportsGrid.propTypes = {
  rows: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  fetching: PropTypes.func.isRequired,
  onSortingChange: PropTypes.func.isRequired,
  sorting: PropTypes.array.isRequired,
  sortingColumnExtensions: PropTypes.array,
  filters: PropTypes.array.isRequired,
  onFiltersChange: PropTypes.func.isRequired,
  filtersColumnExtensions: PropTypes.array,
  defaultGrouping: PropTypes.array,
  groupingColumnExtensions: PropTypes.array,
  expandedGroups: PropTypes.array,
  onExpandedGroupsChange: PropTypes.func,
  groupRowProps: PropTypes.object,
  summaryGroupItems: PropTypes.array,
  totalGroupItems: PropTypes.array,
  summaryCalculator: PropTypes.func,
  pager: PropTypes.object.isRequired,
  onCurrentPageChange: PropTypes.func.isRequired
};

export default DailyTimeReportsGrid;
