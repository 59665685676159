import React, { useEffect, useMemo, ChangeEvent } from "react";
import { FormControl, TextField, FormHelperText } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { retrieveDevelopers, contractFormSetContract } from "redux/actions";
import { ContractFormContract } from "../../types/contract";
import { ContractFormErrors } from "../../redux/types/contractForm";

interface DevelopersDropDown {
  contract: ContractFormContract;
  isBeingEdited: boolean;
  fieldsErrors: ContractFormErrors;
}

export default function DevelopersDropDown({
  contract,
  isBeingEdited,
  fieldsErrors
}: DevelopersDropDown) {
  const dispatch = useDispatch();

  // @ts-ignore
  const developers = useSelector(state => state.developers.list);

  useEffect(() => {
    if (developers.length === 0) {
      dispatch(retrieveDevelopers());
    }
  }, [developers, dispatch]);

  type DeveloperOption = {
    value: number;
    label: string;
  };

  const developersOptions: DeveloperOption[] = useMemo(
    () =>
      // @ts-ignore
      developers.map(developer => ({
        value: developer.uid,
        label: developer.name
      })),
    [developers]
  );

  const developerValue = useMemo(() => {
    const developers = developersOptions.filter(
      developersOption => contract.developer_id === developersOption.value
    );
    let developer = null;
    if (developers.length > 0) {
      developer = developers.shift();
    }
    return developer;
  }, [contract.developer_id, developersOptions]);

  return (
    <FormControl
      disabled={!isBeingEdited}
      required
      margin="normal"
      fullWidth
      error={fieldsErrors.developer_id !== undefined}
    >
      <Autocomplete
        id="developer"
        disabled={!isBeingEdited}
        options={developersOptions}
        value={developerValue}
        getOptionLabel={(option: DeveloperOption) => option.label}
        onChange={(event: ChangeEvent<{}>, value: DeveloperOption | null) => {
          if (value !== null) {
            dispatch(
              contractFormSetContract({
                ...contract,
                developer_id: value.value
              })
            );
          }
        }}
        renderInput={params => (
          <TextField
            {...params}
            error={
              !Array.isArray(fieldsErrors) &&
              fieldsErrors.developer_id !== undefined
            }
            label="Developer"
            variant="standard"
            placeholder="Choose a developer"
            required
          />
        )}
      />
      <FormHelperText>
        {fieldsErrors.developer_id !== undefined
          ? fieldsErrors.developer_id
          : ""}
      </FormHelperText>
    </FormControl>
  );
}
