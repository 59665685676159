import moment, {Moment} from "moment";
import React, {useState} from "react";
import {DATE_FORMAT} from "../../variables/general";
import {DateRangePicker, FocusedInputShape} from "react-dates";

const CalendarClass: React.FunctionComponent<any> = function(props: any) {
    const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(
        null
    );

    const today = moment();
    const presets = [
        {
            text: 'Today',
            start: today,
            end: today,
        },
        {
            text: 'Yesterday',
            start: moment().subtract(1, 'day'),
            end: moment().subtract(1, 'day'),
        },
        {
            text: 'This week',
            start: moment().startOf('isoWeek'),
            end: today,
        },
        {
            text: 'Last week',
            start: moment().subtract(1, 'week').startOf('isoWeek'),
            end: moment().startOf('week'),
        },
        {
            text: 'This month',
            start: moment().startOf('month'),
            end: today,
        },
        {
            text: 'Last month',
            start: moment().subtract(1, 'month').startOf('month'),
            end: moment().subtract(1, 'month').endOf('month'),
        },
        {
            text: 'Last 3 month',
            start: moment().subtract(3, 'month').startOf('month'),
            end: moment().subtract(1, 'month').endOf('month'),
        },
        {
            text: 'Last 6 month',
            start: moment().subtract(6, 'month').startOf('month'),
            end: moment().subtract(1, 'month').endOf('month'),
        },
        {
            text: 'This year',
            start: moment().startOf('year'),
            end: today,
        },
        {
            text: 'Last year',
            start: moment().subtract(1, 'year').startOf('year'),
            end: moment().subtract(1, 'year').endOf('year'),
        }];

    class DateRangePickerClass extends React.Component<any, any> {

        constructor(props: any) {
            super(props);
            this.state = {
                startDate: props.startDate,
                endDate: props.endDate,
                onDatesChange: props.onDatesChange
            };
            this.renderDatePresets = this.renderDatePresets.bind(this);
        }

        renderDatePresets() {
            const {onDatesChange} = this.state;
            return (
                <div>
                    {presets.map(({text, start, end}) => {
                        return (
                            <div className={"presetButtonWrapper"}>
                                <button
                                    key={text}
                                    className="datePresetButton"
                                    type="button"
                                    onClick={() => onDatesChange(start, end)}
                                >
                                    {text}
                                </button>
                            </div>

                        );
                    })}
                </div>
            );
        }

        render() {
            const {startDate, endDate, onDatesChange} = this.state;
            return (
                <DateRangePicker
                    calendarInfoPosition="before"
                    startDateId="startDate"
                    endDateId="endDate"
                    startDate={startDate}
                    endDate={endDate}
                    onDatesChange={({startDate, endDate}) => onDatesChange(startDate, endDate)}
                    focusedInput={focusedInput}
                    onFocusChange={focusedInput => {
                        setFocusedInput(focusedInput);
                    }}
                    showDefaultInputIcon={true}
                    block={true}
                    isOutsideRange={() => false}
                    displayFormat={DATE_FORMAT}
                    firstDayOfWeek={1}
                    renderCalendarInfo={this.renderDatePresets}
                    minimumNights={0}
                />
            );
        }
    }
    return (
        <DateRangePickerClass
            startDate={props.startDate}
            endDate={props.endDate}
            onDatesChange={props.onDatesChangeHandler}
        />
    )
}

export default CalendarClass;
