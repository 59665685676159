import React, { useEffect, useMemo } from "react";
import {
  Input,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  CircularProgress,
  Box,
  Button
} from "@material-ui/core";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import moment from "moment";
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import { RouteComponentProps } from "react-router-dom";
import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";
import { makeStyles } from "@material-ui/styles";
import { useDispatch, useSelector } from "react-redux";
import { DATE_FORMAT, ACCOUNT_MANAGERS } from "../../variables/general";
import {
  contractReportsSetParams,
  contractReportsSetFilters,
  contractReportsResetFilters,
  contractReportsResetParams,
  contractReportsRetrieveContractReports,
  contractReportsSetSorting
} from "../../redux/actions";
import { ContractTimeReport } from "../../types/timeReport";
import { ContractReportsParamsState } from "../../redux/types/contractReports";
import ContractReportsGrid from "../../components/TimeReports/ContractReportsGrid";
import { IntegratedSummary } from "@devexpress/dx-react-grid";
import DateRangePickerClass from "./DateRangePickerClass";
import { Role } from "types/user";

// @ts-ignore
const useStyles = makeStyles(dashboardStyle);

const ContractReports: React.FunctionComponent<
  RouteComponentProps<{}>
> = function(props: RouteComponentProps<{}>) {
  const dispatch = useDispatch();

  const contractReports: ContractTimeReport[] = useSelector(
    // @ts-ignore
    state => state.contractReports.data
  );
  const params: ContractReportsParamsState = useSelector(
    // @ts-ignore
    state => state.contractReports.params
  );
  const filters = useSelector(
    // @ts-ignore
    state => state.contractReports.filters
  );
  const sorting = useSelector(
    // @ts-ignore
    state => state.contractReports.sorting
  );
  const fetching: boolean = useSelector(
    // @ts-ignore
    state => state.contractReports.fetching
  );
  const currentUser = useSelector(
    // @ts-ignore
    state => state.currentUser.user
  );

  const classes = useStyles();
  useEffect(() => {
    dispatch(contractReportsRetrieveContractReports());
  }, [dispatch]);

  const columns = [
    { name: "developerName", title: "Developer" },
    { name: "project", title: "Project" },
    { name: "projectGroup", title: "Project Group" },
    { name: "client", title: "Client" },
    { name: "hours", title: "Hours" }
  ];

  const showExportButton =
    currentUser.roles.includes(Role.HeadOfDepartment) ||
    currentUser.roles.includes(Role.Manager) ||
    currentUser.roles.includes(Role.StaffingManager);

  const exportColumns = [
    { name: "developerName", title: "Developer" },
    { name: "position", title: "Position"},
    { name: "project", title: "Project" },
    { name: "projectGroup", title: "Project Group" },
    { name: "client", title: "Client" },
    { name: "hours", title: "Hours" },
    {
      name: "lastHoursConfirmationDate",
      title: "Total hours (Approved/Not approved)"
    },
    { name: "hourlyRate", title: "Rate" },
  ];

  const grid = (
    <ContractReportsGrid
      rows={contractReports}
      columns={columns}
      sorting={sorting}
      onSortingChange={sorting => {
        dispatch(contractReportsSetSorting(sorting));
      }}
      defaultGrouping={[{ columnName: "developerName" }]}
      groupingColumnExtensions={[
        { columnName: "client", groupingEnabled: false },
        { columnName: "hours", groupingEnabled: false }
      ]}
      filters={filters}
      onFiltersChange={filters => {
        dispatch(contractReportsSetFilters(filters));
      }}
      filtersColumnExtensions={[
        {
          columnName: "client",
          filteringEnabled: false
        },
        {
          columnName: "hours",
          filteringEnabled: false
        }
      ]}
      filterRowProps={{
        visible: true
      }}
      groupRowProps={{
        messages: {
          sum_hours: "Sum"
        },
        columnExtensions: [
          {
            columnName: "developerName",
            showWhenGrouped: true
          }
        ]
      }}
      summaryGroupItems={[
        {
          columnName: "hours",
          type: "sum_hours",
          showInGroupFooter: false,
          alignByColumn: true
        }
      ]}
      summaryCalculator={(type, rows, getValue) => {
        if (type === "sum_hours") {
          // When sum hours we need to be sure that we have only 2 decimals after floating point.
          return (
            Math.round(
              IntegratedSummary.defaultCalculator("sum", rows, getValue) * 100
            ) / 100
          );
        }
        return IntegratedSummary.defaultCalculator(type, rows, getValue);
      }}
      showExportButton={showExportButton}
      exportColumns={exportColumns}
      exportColumnExtensions={[
        { columnName: "hours", align: "right", width: "100px" },
        { columnName: "hourlyRate", align: "right", width: "100px" }
      ]}
    />
  );

  function handleDatesChange(startDate: moment.Moment, endDate: moment.Moment) {
    const newParams = { ...params };
    // endDate is null when endDate < startDate
    if (endDate == null) endDate = startDate;
    newParams.startDate =
      startDate !== null ? startDate.format(DATE_FORMAT) : newParams.startDate;
    newParams.endDate = endDate.format(DATE_FORMAT);
    dispatch(contractReportsSetParams(newParams));
    dispatch(contractReportsRetrieveContractReports());
  }

  return (
    <div>
      <form className={classes.filtersForm} autoComplete="off">
        <GridContainer>
          <GridItem container alignItems="center" xs={12} sm={4} md={2}>
            <FormControl className={classes.formControl} fullWidth>
              <InputLabel shrink htmlFor="account-manager-label-placeholder">
                Account manager
              </InputLabel>
              <Select
                value={params.accountManagerId}
                onChange={event => {
                  dispatch(
                    contractReportsSetParams({
                      ...params,
                      accountManagerId: event.target.value as number
                    })
                  );
                  dispatch(contractReportsRetrieveContractReports());
                }}
                input={
                  <Input
                    name="account-manager"
                    id="account-manager-label-placeholder"
                  />
                }
                displayEmpty
                name="account-manager"
                className={classes.selectEmpty}
              >
                <MenuItem value={0}>
                  <em>All</em>
                </MenuItem>
                {ACCOUNT_MANAGERS.map(accountManager => (
                  <MenuItem key={accountManager.uid} value={accountManager.uid}>
                    {accountManager.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </GridItem>
          <GridItem container alignItems="center" xs={12} sm={8} md={9}>
            <DateRangePickerClass
              startDate={moment(params.startDate, DATE_FORMAT)}
              endDate={moment(params.endDate, DATE_FORMAT)}
              onDatesChangeHandler={handleDatesChange}
            />
            <Button
              className={classes.resetFiltersBtn}
              type="submit"
              variant="contained"
              color="primary"
              onClick={event => {
                dispatch(contractReportsResetParams());
                dispatch(contractReportsResetFilters());
                dispatch(contractReportsRetrieveContractReports());
                event.preventDefault();
              }}
            >
              Reset filters
            </Button>
          </GridItem>
          <GridItem xs={12} sm={4} md={1}></GridItem>
        </GridContainer>
      </form>
      <Box style={{ display: "flex", justifyContent: "center" }}>
        {fetching ? <CircularProgress className={classes.progress} /> : grid}
      </Box>
    </div>
  );
};

export default ContractReports;
