import React from "react";
import SwapVertIcon from "@material-ui/icons/SwapVert";
import { Button } from "@material-ui/core";

function SortButton({ onSort, children, direction }) {
  return (
    <Button onClick={onSort} className="sortButton">
      {children}
      {direction && <SwapVertIcon style={{ fontSize: "18px" }} />}
    </Button>
  );
}

export default SortButton;
