import * as actionTypes from "../actionTypes";

export const timeReportFormRetrieveTimeReport = id => ({
  type: actionTypes.TIME_REPORT_FORM_RETRIEVE_TIME_REPORT,
  payload: { id }
});

export const timeReportFormSetContract = contract => ({
  type: actionTypes.TIME_REPORT_FORM_SET_CONTRACT,
  payload: { contract }
});

export const timeReportFormSetDeveloper = developer => ({
  type: actionTypes.TIME_REPORT_FORM_SET_DEVELOPER,
  payload: { developer }
});

export const timeReportFormSetTimeReport = timeReport => ({
  type: actionTypes.TIME_REPORT_FORM_SET_TIME_REPORT,
  payload: { timeReport }
});

export const timeReportFormSendTimeReport = () => ({
  type: actionTypes.TIME_REPORT_FORM_SEND_TIME_REPORT,
  payload: {}
});

export const timeReportFormDeleteTimeReport = () => ({
  type: actionTypes.TIME_REPORT_FORM_DELETE_TIME_REPORT,
  payload: {}
});

export const timeReportFormSetRetrieveTimeReportErrors = errors => ({
  type: actionTypes.TIME_REPORT_FORM_SET_RETRIEVE_TIME_REPORT_ERRORS,
  payload: { errors }
});

export const timeReportFormSetSendTimeReportErrors = errors => ({
  type: actionTypes.TIME_REPORT_FORM_SET_SEND_TIME_REPORT_ERRORS,
  payload: { errors }
});

export const timeReportFormResetTimeReport = () => ({
  type: actionTypes.TIME_REPORT_FORM_RESET_TIME_REPORT,
  payload: {}
});

export const timeReportFormResetContract = () => ({
  type: actionTypes.TIME_REPORT_FORM_RESET_CONTRACT,
  payload: {}
});

export const timeReportFormResetRetrieveTimeReportErrors = () => ({
  type: actionTypes.TIME_REPORT_FORM_RESET_RETRIEVE_TIME_REPORT_ERRORS,
  payload: {}
});

export const timeReportFormResetSendTimeReportErrors = () => ({
  type: actionTypes.TIME_REPORT_FORM_RESET_SEND_TIME_REPORT_ERRORS,
  payload: {}
});
