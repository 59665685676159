import { Filter, Sorting } from "@devexpress/dx-react-grid";
import { ContractTimeReport } from "../../types/timeReport";

export const CONTRACT_REPORTS_RETRIEVE_CONTRACT_REPORTS =
  "CONTRACT_REPORTS_RETRIEVE_CONTRACT_REPORTS";
export const CONTRACT_REPORTS_SET_CONTRACT_REPORTS =
  "CONTRACT_REPORTS_SET_CONTRACT_REPORTS";
export const CONTRACT_REPORTS_SET_PARAMS = "CONTRACT_REPORTS_SET_PARAMS";
export const CONTRACT_REPORTS_SET_FILTERS = "CONTRACT_REPORTS_SET_FILTERS";
export const CONTRACT_REPORTS_SET_SORTING = "CONTRACT_REPORTS_SET_SORTING";
export const CONTRACT_REPORTS_RESET_PARAMS = "CONTRACT_REPORTS_RESET_PARAMS";
export const CONTRACT_REPORTS_RESET_FILTERS = "CONTRACT_REPORTS_RESET_FILTERS";
export const CONTRACT_REPORTS_RESET_SORTING = "CONTRACT_REPORTS_RESET_SORTING";

type ContractReportsRetrieveContractReportsAction = {
  type: typeof CONTRACT_REPORTS_RETRIEVE_CONTRACT_REPORTS;
};

type ContractReportsSetContractReportsAction = {
  type: typeof CONTRACT_REPORTS_SET_CONTRACT_REPORTS;
  payload: ContractTimeReport[];
};

type ContractReportsSetParamsAction = {
  type: typeof CONTRACT_REPORTS_SET_PARAMS;
  payload: ContractReportsParamsState;
};

type ContractReportsResetParamsAction = {
  type: typeof CONTRACT_REPORTS_RESET_PARAMS;
};

type ContractReportsSetFiltersAction = {
  type: typeof CONTRACT_REPORTS_SET_FILTERS;
  payload: Filter[];
};

type ContractReportsResetFiltersAction = {
  type: typeof CONTRACT_REPORTS_RESET_FILTERS;
};

type ContractReportsSetSortingAction = {
  type: typeof CONTRACT_REPORTS_SET_SORTING;
  payload: Sorting[];
};

type ContractReportsResetSortingAction = {
  type: typeof CONTRACT_REPORTS_RESET_SORTING;
};

export type ContractReportsActionTypes =
  | ContractReportsRetrieveContractReportsAction
  | ContractReportsSetContractReportsAction
  | ContractReportsSetParamsAction
  | ContractReportsResetParamsAction
  | ContractReportsSetFiltersAction
  | ContractReportsResetFiltersAction
  | ContractReportsSetSortingAction
  | ContractReportsResetSortingAction;

export type ContractReportsParamsState = {
  accountManagerId: number;
  startDate: string;
  endDate: string;
};

export type ContractReportsState = {
  fetching: boolean;
  filters: Filter[];
  data: ContractTimeReport[];
  params: ContractReportsParamsState;
  sorting: Sorting[];
};
