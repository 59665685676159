import {
  RETRIEVE_CURRENT_USER,
  SET_CURRENT_USER,
  CurrentUserState,
  CurrentUserActionTypes
} from "../types/currentUser";

const initialState: CurrentUserState = {
  fetching: false,
  user: null
};

export default function(
  state: CurrentUserState = initialState,
  action: CurrentUserActionTypes
): CurrentUserState {
  switch (action.type) {
    case SET_CURRENT_USER:
      return { user: { ...action.payload }, fetching: false };
    case RETRIEVE_CURRENT_USER:
      return { ...state, fetching: true };
    default:
      return state;
  }
}
