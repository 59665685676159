import React, { SetStateAction, Dispatch } from "react";
import { Snackbar, SnackbarContent } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

const contractFormStyles = (theme: Theme) => ({
  snackbar: {
    // @ts-ignore
    ...theme.snackBarSuccess,
    // @ts-ignore
    ...theme.snackBar
  },
  snackbarMessage: {
    // @ts-ignore
    ...theme.snackBarMessage
  },
  snackbarIcon: {
    // @ts-ignore
    ...theme.snackbarIcon
  }
});

const useStyles = makeStyles(contractFormStyles);

interface SnackBarProps {
  showSnackBar: boolean;
  setShowSnackBar: Dispatch<SetStateAction<boolean>>;
}

export default function SnackBar({
  showSnackBar,
  setShowSnackBar
}: SnackBarProps) {
  const classes = useStyles();

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left"
      }}
      open={showSnackBar}
      onClose={(event, reason) => {
        if (reason === "timeout") {
          setShowSnackBar(false);
        }
      }}
      autoHideDuration={2000}
    >
      <SnackbarContent
        aria-describedby="snackbar-message"
        className={classes.snackbar}
        message={
          <span id="snackbar-message" className={classes.snackbarMessage}>
            <CheckCircleIcon className={classes.snackbarIcon} />
            Contract saved!
          </span>
        }
      />
    </Snackbar>
  );
}
