import React, { useEffect, useState, MouseEvent, ChangeEvent } from "react";
import {
  FormControl,
  InputLabel,
  Button,
  Paper,
  MenuItem,
  Select,
  TextField,
  Grid,
  CircularProgress,
  FormLabel,
  FormHelperText,
  Snackbar,
  SnackbarContent
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { useDispatch, useSelector } from "react-redux";
import {
  projectFormRetrieveProject,
  projectFormSetProject,
  projectFormSendProject,
  projectFormResetProject,
  projectFormResetRetrieveProjectErrors,
  projectFormResetSendProjectErrors,
  retrieveAccountManagers,
  generalRetrieveClientNames,
  projectGroupsRetrieveGroups
} from "redux/actions";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";
import { useIsContractOperationsAllowed } from "../../hooks";
import { RouteComponentProps } from "react-router-dom";
import { ProjectFormProject, ProjectStatus } from "../../types/project";
import { Errors } from "../../redux/types/projectForm";
import { SimpleUser } from "../../types/user";
import { ProjectGroup } from "../../types/projectGroup";

const projectFormStyles = (theme: Theme) => ({
  projectFormPaper: {
    padding: "10px",
    display: "flex",
    justifyContent: "center"
  },
  buttonSpinner: {
    margin: "5px"
  },
  snackbar: {
    // @ts-ignore
    ...theme.snackBarSuccess,
    // @ts-ignore
    ...theme.snackBar
  },
  snackbarMessage: {
    // @ts-ignore
    ...theme.snackBarMessage
  },
  snackbarIcon: {
    // @ts-ignore
    ...theme.snackbarIcon
  }
});

const useStyles = makeStyles(projectFormStyles);

const ProjectForm: React.FunctionComponent<
  RouteComponentProps<{ id?: string }>
> = function(props: RouteComponentProps<{ id?: string }>) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { match, history } = props;

  const projectId = (match.params.id && parseInt(match.params.id)) || 0;

  const [sendProjectAttempt, setSendProjectAttempt] = useState<boolean>(false);
  const [showSnackBar, setShowSnackBar] = useState<boolean>(false);
  const [isBeingEdited, setIsBeingEdited] = useState<boolean>(projectId === 0);

  const projectOperationsAllowed = useIsContractOperationsAllowed();

  // @ts-ignore
  let clientNames: string[] = useSelector(state => state.general.clientNames);

  useEffect(() => {
    dispatch(projectFormResetRetrieveProjectErrors());
    dispatch(projectFormResetSendProjectErrors());
    if (projectId !== 0) {
      dispatch(projectFormRetrieveProject(projectId));
    } else {
      dispatch(projectFormResetProject());
    }
  }, [projectId, dispatch]);

  const project: ProjectFormProject = useSelector(
    // @ts-ignore
    state => state.projectForm.data
  );

  const accountManagers: SimpleUser[] = useSelector(
    // @ts-ignore
    state => state.accountManagers.data
  );
  const projectFetching: boolean = useSelector(
    // @ts-ignore
    state => state.projectForm.fetching
  );
  const projectSending: boolean = useSelector(
    // @ts-ignore
    state => state.projectForm.sending
  );
  const retrieveErrors: Errors | null = useSelector(
    // @ts-ignore
    state => state.projectForm.retrieveErrors
  );
  const sendErrors: Errors | null = useSelector(
    // @ts-ignore
    state => state.projectForm.sendErrors
  );
  const groups: ProjectGroup[] = useSelector(
    // @ts-ignore
    state => state.projectGroups.data
  );
  const groupsInitialized = useSelector(
    // @ts-ignore
    state => state.projectGroups.initialized
  );
  const groupsFetching = useSelector(
    // @ts-ignore
    state => state.projectGroups.fetching
  );

  console.log(groups);

  useEffect(() => {
    if (clientNames.length === 0) {
      dispatch(generalRetrieveClientNames());
    } else {
      clientNames = clientNames.map(client => client.trim());

      if (
        project.client !== "" &&
        clientNames.indexOf(project.client.trim()) === -1
      ) {
        // If project client value is not from client names list, force user to fill it properly.
        dispatch(projectFormSetProject({ ...project, client: "" }));
      }
    }
  }, [clientNames, project, dispatch]);

  useEffect(() => {
    if (!groupsInitialized) {
      dispatch(projectGroupsRetrieveGroups());
    }
  }, [dispatch, groupsInitialized]);

  const formErrors = [];
  let fieldsErrors: Errors = {};
  if (sendErrors !== null) {
    if (Array.isArray(sendErrors)) {
      for (const error of sendErrors) {
        formErrors.push(<FormLabel error={true}>{error}</FormLabel>);
      }
    } else {
      formErrors.push(
        <FormLabel error={true}>Please fix all errors</FormLabel>
      );
      fieldsErrors = sendErrors;
    }
  }

  if (sendProjectAttempt && !projectSending) {
    // If we just created a project, then we need to redirect on project form.
    if (projectId === 0 && project.id !== 0) {
      history.push(`/project/${project.id}`);
    }
    setSendProjectAttempt(false);
    setShowSnackBar(sendErrors === null);
  }

  useEffect(() => {
    dispatch(retrieveAccountManagers());
  }, [dispatch]);

  const sendForm = (event: MouseEvent<HTMLButtonElement>) => {
    setSendProjectAttempt(true);
    dispatch(projectFormSendProject(project));
    event.preventDefault();
  };

  const form = (
    <form>
      <Grid container spacing={2}>
        {formErrors.length > 0 && (
          <Grid item xs={12}>
            {formErrors}
          </Grid>
        )}
        <Grid item xs={12}>
          <TextField
            id="name"
            name="name"
            disabled={!isBeingEdited}
            fullWidth
            label="Name"
            required
            value={project.name}
            onChange={event => {
              dispatch(
                projectFormSetProject({
                  ...project,
                  name: event.target.value
                })
              );
            }}
            error={Boolean(fieldsErrors.name)}
            helperText={fieldsErrors.name || ""}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl
            required
            disabled={!isBeingEdited}
            fullWidth
            component={"div"}
            error={Boolean(fieldsErrors.account_manager_id)}
          >
            <InputLabel htmlFor="account_manager_id">
              Account Manager
            </InputLabel>
            <Select
              value={project.account_manager_id}
              onChange={event => {
                dispatch(
                  projectFormSetProject({
                    ...project,
                    account_manager_id: event.target.value as number
                  })
                );
              }}
              inputProps={{
                name: "account_manager_id",
                id: "account_manager_id",
                fullWidth: true,
                required: true
              }}
            >
              {accountManagers.map(({ uid, name }) => (
                <MenuItem value={uid} key={uid}>
                  {name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>
              {fieldsErrors.account_manager_id || ""}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl
            required
            disabled={!isBeingEdited}
            fullWidth
            component={"div"}
            error={Boolean(fieldsErrors.project_group_id)}
          >
            <InputLabel htmlFor="prject_group_id">
              Project Group
            </InputLabel>
            <Select
              value={project.project_group_id}
              onChange={event => {
                dispatch(
                  projectFormSetProject({
                    ...project,
                    project_group_id: event.target.value as number
                  })
                );
              }}
              inputProps={{
                name: "project_group_id",
                id: "project_group_id",
                fullWidth: true,
                required: true
              }}
            >
              {groups.map(({ id, name }) => (
                <MenuItem value={id} key={id}>
                  {name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>
              {fieldsErrors.account_manager_id || ""}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl
            required
            disabled={!isBeingEdited}
            fullWidth
            component={"div"}
            error={
              !Array.isArray(fieldsErrors) && fieldsErrors.client !== undefined
            }
          >
            <Autocomplete
              id="client"
              disabled={!isBeingEdited}
              options={clientNames}
              value={project.client}
              onChange={(event: ChangeEvent<{}>, value: string | null) => {
                if (value !== null) {
                  dispatch(
                    projectFormSetProject({
                      ...project,
                      client: value
                    })
                  );
                }
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  error={
                    !Array.isArray(fieldsErrors) &&
                    fieldsErrors.client !== undefined
                  }
                  label="Client"
                  variant="standard"
                  placeholder="Choose a client"
                  required
                />
              )}
            />
            <FormHelperText>
              {!Array.isArray(fieldsErrors) && fieldsErrors.client !== undefined
                ? fieldsErrors.client
                : ""}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container>
            <Grid item xs={12}>
              <FormControl
                required
                disabled={!isBeingEdited}
                fullWidth
                component={"div"}
                error={Boolean(fieldsErrors.status)}
              >
                <InputLabel htmlFor="status">Status</InputLabel>
                <Select
                  disabled={!isBeingEdited}
                  value={project.status}
                  onChange={event => {
                    const newProject = { ...project };
                    newProject.status = event.target.value as ProjectStatus;
                    dispatch(projectFormSetProject(newProject));
                  }}
                  inputProps={{
                    name: "status",
                    id: "status",
                    fullWidth: true
                  }}
                >
                  <MenuItem value={ProjectStatus.Active}>Active</MenuItem>
                  <MenuItem value={ProjectStatus.Paused}>Paused</MenuItem>
                  <MenuItem value={ProjectStatus.Closed}>Closed</MenuItem>
                </Select>
                <FormHelperText>
                  {project.status !== ProjectStatus.Active
                    ? "Please note that all attached contracts will also change their status to this one"
                    : ""}
                </FormHelperText>
                <FormHelperText>{fieldsErrors.status || ""}</FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {isBeingEdited || projectId === 0 ? (
            <Button
              type="button"
              variant="contained"
              color="primary"
              onClick={sendForm}
            >
              {projectId === 0 ? "Create project" : "Update project"}
              {projectSending && (
                <CircularProgress
                  size={20}
                  className={classes.buttonSpinner}
                  color="secondary"
                />
              )}
            </Button>
          ) : (
            <Button
              type="button"
              variant="contained"
              color="primary"
              onClick={() => {
                setIsBeingEdited(true);
              }}
            >
              Edit project
            </Button>
          )}
        </Grid>
      </Grid>
    </form>
  );

  const errorMessages = [];
  if (retrieveErrors !== null) {
    if (Array.isArray(retrieveErrors)) {
      for (const error of retrieveErrors) {
        errorMessages.push(<FormLabel error={true}>{error}</FormLabel>);
      }
    } else {
      for (const [key, error] of Object.entries(retrieveErrors)) {
        errorMessages.push(
          <FormLabel key={key} error={true}>
            {key}: {error}
          </FormLabel>
        );
      }
    }
  }

  const snackBar = (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left"
      }}
      open={showSnackBar}
      onClose={(event, reason) => {
        if (reason === "timeout") {
          setShowSnackBar(false);
        }
      }}
      autoHideDuration={2000}
    >
      <SnackbarContent
        aria-describedby="snackbar-message"
        className={classes.snackbar}
        message={
          <span id="snackbar-message" className={classes.snackbarMessage}>
            <CheckCircleIcon className={classes.snackbarIcon} />
            Project saved!
          </span>
        }
      />
    </Snackbar>
  );

  return (
    <div>
      {projectOperationsAllowed ? (
        <Paper className={classes.projectFormPaper}>
          {projectFetching || groupsFetching ? (
            <CircularProgress />
          ) : errorMessages.length > 0 ? (
            errorMessages
          ) : (
            form
          )}
          {snackBar}
        </Paper>
      ) : (
        "Forbidden"
      )}
    </div>
  );
};

export default ProjectForm;
