import React, { ChangeEvent } from "react";
import { useDispatch } from "react-redux";
import { contractFormSetContract } from "redux/actions";
import { ContractFormErrors } from "redux/types/contractForm";
import { ContractFormContract, ContractPosition } from "../../types/contract";
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@material-ui/core";
import { CONTRACT_POSITIONS } from "variables/general";

interface PositionDropDownProps {
  contract: ContractFormContract;
  isBeingEdited: boolean;
  fieldsErrors: ContractFormErrors;
}

export default function PositionDropDown({contract, isBeingEdited, fieldsErrors}: PositionDropDownProps) {
  const dispatch = useDispatch();

  const changePosition = (
    event: ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    dispatch(
      contractFormSetContract({
        ...contract,
        position: event.target.value as ContractPosition
      })
    );
  };

  return (
    <FormControl
        disabled={!isBeingEdited}
        fullWidth
        component={"div"}
        error={fieldsErrors.position !== undefined}
        required
      >
        <InputLabel htmlFor="type">Position</InputLabel>
        <Select
          value={contract.position}
          onChange={changePosition}
          inputProps={{
            name: "position",
            id: "position",
            fullWidth: true
          }}
        >
          {CONTRACT_POSITIONS.map(position => {
            return <MenuItem value={position.label}>{position.label}</MenuItem>
          })}
        </Select>
        <FormHelperText>
          {fieldsErrors.position !== undefined
          ? fieldsErrors.position
          : ""}
        </FormHelperText>
    </FormControl>
  )
}
