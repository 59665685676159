import {
  PROJECT_GROUPS_RETRIEVE_GROUPS,
  PROJECT_GROUPS_SET_GROUPS,
  PROJECT_GROUPS_SET_SHOW_SNACKBAR,
  ProjectGroupsActionTypes
} from "../types/projectGroups";
import { ProjectGroup } from "../../types/projectGroup";

export function projectGroupsRetrieveGroups(): ProjectGroupsActionTypes {
  return {
    type: PROJECT_GROUPS_RETRIEVE_GROUPS
  };
}

export function projectGroupsSetGroups(
  groups: ProjectGroup[]
): ProjectGroupsActionTypes {
  return {
    type: PROJECT_GROUPS_SET_GROUPS,
    payload: groups
  };
}

export function projectGroupsSetShowToaster(
  show: boolean
): ProjectGroupsActionTypes {
  return {
    type: PROJECT_GROUPS_SET_SHOW_SNACKBAR,
    payload: show
  };
}
