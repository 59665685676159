import {
  PROJECT_GROUPS_RETRIEVE_GROUPS,
  PROJECT_GROUPS_SET_GROUPS,
  PROJECT_GROUPS_SET_SHOW_SNACKBAR,
  ProjectGroupsActionTypes,
  ProjectGroupsState
} from "../types/projectGroups";

const initialState: ProjectGroupsState = {
  fetching: false,
  data: [],
  initialized: false,
  showSnackbar: false
};

export default function(
  state: ProjectGroupsState = initialState,
  action: ProjectGroupsActionTypes
) {
  switch (action.type) {
    case PROJECT_GROUPS_RETRIEVE_GROUPS:
      return { ...state, fetching: true };
    case PROJECT_GROUPS_SET_GROUPS:
      return {
        ...state,
        data: action.payload,
        fetching: false,
        initialized: true
      };
    case PROJECT_GROUPS_SET_SHOW_SNACKBAR:
      return { ...state, showSnackbar: action.payload };
    default:
      return state;
  }
}
