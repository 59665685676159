import {
  GENERAL_RETRIEVE_CLIENT_NAMES,
  GENERAL_SET_CLIENT_NAMES,
  generalActionTypes,
  GeneralState
} from "../types/general";

const initialState: GeneralState = {
  clientNames: [],
  fetchingClientNames: false
};

export default function(
  state: GeneralState = initialState,
  action: generalActionTypes
) {
  switch (action.type) {
    case "GENERAL_RETRIEVE_CLIENT_NAMES":
      return { ...state, fetchingClientNames: true };
    case "GENERAL_SET_CLIENT_NAMES":
      return {
        ...state,
        clientNames: action.payload,
        fetchingClientNames: false
      };
    default:
      return state;
  }
}
