import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import {
  Input,
  InputLabel,
  MenuItem,
  Fab,
  FormControl,
  Select,
  CircularProgress,
  Box,
  Button,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import moment from "moment";
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import { Link, RouteComponentProps } from "react-router-dom";
import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";
import { makeStyles } from "@material-ui/styles";
import { useDispatch, useSelector } from "react-redux";
import { DATE_FORMAT, ACCOUNT_MANAGERS } from "../../variables/general";
import {
  dailyTimeReportsRetrieveTimeReports,
  dailyTimeReportsSetParams,
  dailyTimeReportsSetPager,
  dailyTimeReportsSetFilters,
  dailyTimeReportsSetSorting,
  dailyTimeReportsResetParams,
  dailyTimeReportsResetFilters,
  dailyTimeReportsResetSorting,
  dailyTimeReportsResetPager
} from "redux/actions";
import DailyTimeReportsGrid from "../../components/TimeReports/DailyTimeReportsGrid";
import { IntegratedSummary } from "@devexpress/dx-react-grid";
import DateRangePickerClass from "./DateRangePickerClass";
import { projectsRetrieveProjects } from "../../redux/actions";

// @ts-ignore
const useStyles = makeStyles(dashboardStyle);

function DailyTimeReports(props: RouteComponentProps<{}>) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [expandedGroups, setExpandedGroups] = useState<string[]>([]);

  const timeReports = useSelector(
      // @ts-ignore
      state => state.dailyTimeReports.list);
  const params = useSelector(
      // @ts-ignore
      state => state.dailyTimeReports.params);
  const filters = useSelector(
      // @ts-ignore
      state => state.dailyTimeReports.filters);
  const sorting = useSelector(
      // @ts-ignore
      state => state.dailyTimeReports.sorting);
  const pager = useSelector(
      // @ts-ignore
      state => state.dailyTimeReports.pager);
  const fetching = useSelector(
      // @ts-ignore
      state => state.dailyTimeReports.fetching);
  const projects = useSelector(
      // @ts-ignore
      state => state.projects.list);

  useEffect(() => {
    dispatch(dailyTimeReportsRetrieveTimeReports());
    dispatch(projectsRetrieveProjects());
  }, [dispatch]);

  const biggestDate: string = useMemo(() => {
    let biggestDate = "";
    timeReports.forEach((timeReport: any) => {
      biggestDate =
        timeReport.day_worked_on > biggestDate
          ? timeReport.day_worked_on
          : biggestDate;
    });
    return biggestDate;
  }, [timeReports]);

  useEffect(() => setExpandedGroups([biggestDate]), [biggestDate, timeReports]);

  const getProjectNameById = (id: number) => {
    const project = projects.find((project: any) => project.id === id);

    return project ? project.name : null;
  };

  const columns = [
    {
      name: "developer_name",
      title: "Developer",
    },
    {
      name: "contract_name",
      title: "Contract",
    },
    {
      name: "project_name",
      title: "Project",
      getCellValue: (row: any) =>
        row.project_id ? getProjectNameById(row.project_id) : null
    },
    {
      name: "client_name",
      title: "Client",
    },
    {
      name: "day_worked_on",
      title: "Day",
    },
    {
      name: "hours",
      title: "Hours",
    },
  ];

  const grid = (
    <DailyTimeReportsGrid
      rows={timeReports}
      columns={columns}
      fetching={fetching}
      onSortingChange={sorting => {
        dispatch(dailyTimeReportsSetSorting(sorting));
      }}
      sorting={sorting}
      filters={filters}
      onFiltersChange={filters => {
        dispatch(dailyTimeReportsSetFilters(filters));
      }}
      filtersColumnExtensions={[
        {
          columnName: "day_worked_on",
          filteringEnabled: false,
        },
        {
          columnName: "hours",
          filteringEnabled: false,
        },
      ]}
      defaultGrouping={[
        { columnName: "day_worked_on" },
        { columnName: "developer_name" }
      ]}
      groupingColumnExtensions={[
        {
          columnName: "hours",
          groupingEnabled: false,
        },
        {
          columnName: "day_worked_on",
          groupingEnabled: false,
        },
        {
          columnName: "contract_name",
          groupingEnabled: false,
        },
        {
          columnName: "client_name",
          groupingEnabled: false,
        },
      ]}
      groupRowProps={{
        messages: {
          sum_hours: "Sum",
        },
      }}
      expandedGroups={expandedGroups}
      onExpandedGroupsChange={expandedGroups =>
        setExpandedGroups(expandedGroups)
      }
      summaryGroupItems={[
        {
          columnName: "hours",
          type: "sum_hours",
          showInGroupFooter: false,
          alignByColumn: true,
        },
      ]}
      summaryCalculator={(type, rows, getValue) => {
        if (type === "sum_hours") {
          // When use some other groupings here can be duplicates.
          let rowIds: any = [];
          rows = rows.filter((row: any) => {
            if (!rowIds.hasOwnProperty(row.id)) {
              rowIds[row.id as keyof typeof rowIds] = true;
              return true;
            }
            return false;
          });
          // When sum hours we need to be sure that we have only 2 decimals after floating point.
          return (
            Math.round(
              IntegratedSummary.defaultCalculator("sum", rows, getValue) * 100
            ) / 100
          );
        }
        return IntegratedSummary.defaultCalculator(type, rows, getValue);
      }}
      pager={pager}
      onCurrentPageChange={page => {
        dispatch(dailyTimeReportsSetPager({ ...pager, currentPage: page + 1 }));
        dispatch(dailyTimeReportsRetrieveTimeReports());
      }}
    />
  );

  function handleDatesChange(startDate: moment.Moment, endDate: moment.Moment) {
    // endDate is null when endDate < startDate
    if (endDate == null) endDate = startDate
    dispatch(
      dailyTimeReportsSetParams({
        ...params,
        startDate: startDate!.format(DATE_FORMAT),
        endDate: endDate.format(DATE_FORMAT)
      })
    );
    dispatch(dailyTimeReportsResetPager());
    dispatch(dailyTimeReportsRetrieveTimeReports());
  }

  return (
    <div>
      <form className={classes.filtersForm} autoComplete="off">
        <GridContainer>
          <GridItem container alignItems="center" xs={12} sm={4} md={2}>
            <FormControl className={classes.formControl} fullWidth>
              <InputLabel shrink htmlFor="account-manager-label-placeholder">
                Account manager
              </InputLabel>
              <Select
                value={params.accountManagerId}
                onChange={event => {
                  dispatch(
                    dailyTimeReportsSetParams({
                      ...params,
                      accountManagerId: event.target.value
                    })
                  );
                  dispatch(dailyTimeReportsResetPager());
                  dispatch(dailyTimeReportsRetrieveTimeReports());
                }}
                input={
                  <Input
                    name="account-manager"
                    id="account-manager-label-placeholder"
                  />
                }
                displayEmpty
                name="account-manager"
                className={classes.selectEmpty}
              >
                <MenuItem value={0}>
                  <em>All</em>
                </MenuItem>
                {ACCOUNT_MANAGERS.map(accountManager => (
                  <MenuItem key={accountManager.uid} value={accountManager.uid}>
                    {accountManager.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </GridItem>
          <GridItem container alignItems="center" xs={12} sm={8} md={9}>
            <DateRangePickerClass
                startDate={moment(params.startDate, DATE_FORMAT)}
                endDate={moment(params.endDate, DATE_FORMAT)}
                onDatesChangeHandler={handleDatesChange}
            />
            <Button
              className={classes.resetFiltersBtn}
              type="submit"
              variant="contained"
              color="primary"
              onClick={event => {
                dispatch(dailyTimeReportsResetParams());
                dispatch(dailyTimeReportsResetFilters());
                dispatch(dailyTimeReportsResetSorting());
                dispatch(dailyTimeReportsResetPager());
                dispatch(dailyTimeReportsRetrieveTimeReports());
                event.preventDefault();
              }}
            >
              Reset filters
            </Button>
            {fetching && (
              <Box className={classes.spinnerWrapper}>
                <CircularProgress />
              </Box>
            )}
          </GridItem>

          <GridItem xs={12} sm={4} md={1}>
            <Link to="/time-report/add">
              <Fab color="primary" style={{ float: "right" }}>
                <AddIcon />
              </Fab>
            </Link>
          </GridItem>
        </GridContainer>
      </form>
      {grid}
    </div>
  );
}

DailyTimeReports.propTypes = {
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default DailyTimeReports;
