import * as actionTypes from "../actionTypes";

export const configRetrieveContractCurrencies = () => ({
  type: actionTypes.CONFIG_RETRIEVE_CONTRACT_CURRENCIES,
  payload: {}
});

export const configSetContractCurrencies = currencies => ({
  type: actionTypes.CONFIG_SET_CONTRACT_CURRENCIES,
  payload: { currencies }
});
