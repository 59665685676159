import React, { useMemo, useRef, useState } from "react";
import { ContractTimeReport } from "../../types/timeReport";
import {
  Column,
  DataTypeProvider,
  DataTypeProviderProps,
  Filter,
  FilteringState,
  Grouping,
  GroupingState,
  GroupKey,
  GroupSummaryItem,
  IntegratedFiltering,
  IntegratedGrouping,
  IntegratedSorting,
  IntegratedSummary,
  Sorting,
  SortingState,
  SummaryState,
  SummaryType
} from "@devexpress/dx-react-grid";
import {
  ExportPanel,
  Grid,
  GroupingPanel,
  Table,
  TableFilterRow,
  TableGroupRow,
  TableHeaderRow,
  Toolbar
} from "@devexpress/dx-react-grid-material-ui";
import { ProjectGroup } from "../../types/projectGroup";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/styles";
import { useIsContractOperationsAllowed } from "../../hooks";
import clsx from "clsx";
import { PROJECT_STATUSES, ROUTER_BASENAME } from "../../variables/general";
import SelectFilterCell from "../Grid/SelectFilterCell";
import { fil } from "date-fns/locale";
import { useHistory } from "react-router-dom";

const tableRowStyles = (theme: any) => ({
  tableRow: {
    cursor: "pointer"
  },
  hovered: {
    backgroundColor: theme.palette.primary.main
  },
  hoveredCell: {
    color: "white"
  }
});

const useTableRowStyles = makeStyles(tableRowStyles);

function TableRow({ row, children, ...restProps }: any) {
  const history = useHistory();
  const classes = useTableRowStyles();
  const [hovered, setHovered] = useState(false);
  const [renderRedirect, setRenderRedirect] = useState(false);
  const canEditContract = useIsContractOperationsAllowed();
  const contractPath = `/project-group/${row.id}`;

  if (renderRedirect) {
    history.push(contractPath);
  }

  // Change font color in cells on row hover.
  const newChildren = useMemo(() => {
    if (hovered) {
      return children.map((child: any) => {
        return React.cloneElement(child, {
          className: classes.hoveredCell
        });
      });
    } else {
      return children;
    }
  }, [hovered, children, classes.hoveredCell]);

  return (
    <Table.Row
      className={clsx(
        canEditContract && classes.tableRow,
        hovered && classes.hovered
      )}
      // @ts-ignore
      onMouseDown={event => {
        if (canEditContract) {
          if (event.button === 0) {
            setRenderRedirect(true);
          } else if (event.button === 1) {
            window.open(
              window.location.origin + ROUTER_BASENAME + contractPath,
              "_blank"
            );
          }
        }
      }}
      onClick={
        canEditContract
          ? () => {
              window.open(
                window.location.origin + ROUTER_BASENAME + contractPath,
                "_blank"
              );
            }
          : null
      }
      onMouseEnter={
        canEditContract
          ? () => {
              setHovered(true);
            }
          : null
      }
      onMouseLeave={
        canEditContract
          ? () => {
              setHovered(false);
            }
          : null
      }
      {...restProps}
      children={newChildren}
    />
  );
}

const FilterCell = (props: TableFilterRow.CellProps) => {
  const { column } = props;
  if (column.name === "status") {
    return (
      <SelectFilterCell
        options={[{ id: "", label: "All" }, ...PROJECT_STATUSES]}
        {...props}
      />
    );
  }
  return <TableFilterRow.Cell {...props} />;
};

export type ProjectGroupsGridProps = {
  rows: ProjectGroup[];
  columns: Column[];
};

const ProjectsFormatter = (props: DataTypeProvider.ValueFormatterProps) =>
  // @ts-ignore
  props.value.map(item => <div>{item.name}</div>);

const ProjectsDataProvider = (props: DataTypeProviderProps) => {
  return (
    <DataTypeProvider
      formatterComponent={ProjectsFormatter}
      {...props}
    ></DataTypeProvider>
  );
};

const ProjectGroupsGrid = (props: ProjectGroupsGridProps) => {
  const { rows, columns } = props;

  return (
    <Paper>
      <Grid rows={rows} columns={columns}>
        <SortingState
          defaultSorting={[{ columnName: "name", direction: "asc" }]}
        />
        <ProjectsDataProvider for={["projects"]} />
        <IntegratedSorting />
        <FilteringState defaultFilters={[]} />
        <IntegratedFiltering
          columnExtensions={[
            {
              columnName: "projects",
              predicate: (value: { id: number; name: string }[], filter) => {
                const searchValue = String(filter.value).toLowerCase();
                for (const item of value) {
                  if (
                    String(item.name)
                      .toLowerCase()
                      .includes(searchValue)
                  ) {
                    return true;
                  }
                }
                return false;
              }
            }
          ]}
        />
        <Table
          rowComponent={TableRow}
          columnExtensions={[{ columnName: "projects", wordWrapEnabled: true }]}
        />
        <TableFilterRow cellComponent={FilterCell} />
        <TableHeaderRow showSortingControls />
        <Toolbar />
      </Grid>
    </Paper>
  );
};

export default ProjectGroupsGrid;
