import {
  TIME_REPORTS_RETRIEVE_TIME_REPORTS,
  TIME_REPORTS_SET_TIME_REPORTS,
  TIME_REPORTS_SET_PARAMS,
  TIME_REPORTS_SET_FILTERS,
  TIME_REPORTS_SET_SORTING,
  TIME_REPORTS_SET_PAGER,
  TIME_REPORTS_RESET_PARAMS,
  TIME_REPORTS_RESET_FILTERS,
  TIME_REPORTS_RESET_SORTING,
  TIME_REPORTS_RESET_PAGER
} from "../actionTypes";
import moment from "moment";
import { DATE_FORMAT } from "../../variables/general";
import { getMondayDate } from "../../helpers";

const initialState = {
  list: [],
  fetching: false,
  params: {
    accountManagerId: 0,
    startDate: getMondayDate().format(DATE_FORMAT),
    endDate: moment().format(DATE_FORMAT)
  },
  filters: [],
  sorting: [
    { columnName: "day_worked_on", direction: "desc" },
    { columnName: "hours", direction: "desc" }
  ],
  pager: {
    total: 0,
    currentPage: 1,
    perPage: 50
  }
};

export default function(state = initialState, action) {
  switch (action.type) {
    case TIME_REPORTS_SET_TIME_REPORTS:
      return {
        ...state,
        list: action.payload.timeReports.map(timeReport => ({
          id: timeReport.id,
          contract_name: timeReport.contract.name,
          client_name: timeReport.contract.client,
          developer_uid: timeReport.contract.developer.uid,
          developer_name: timeReport.contract.developer.name,
          day_worked_on: timeReport.day_worked_on,
          hours: timeReport.hours
        })),
        fetching: false
      };
    case TIME_REPORTS_RETRIEVE_TIME_REPORTS:
      return { ...state, fetching: true };
    case TIME_REPORTS_SET_PARAMS:
      return { ...state, params: { ...action.payload.params } };
    case TIME_REPORTS_SET_FILTERS:
      return { ...state, filters: [...action.payload.filters] };
    case TIME_REPORTS_SET_SORTING:
      return { ...state, sorting: [...action.payload.sorting] };
    case TIME_REPORTS_SET_PAGER:
      return { ...state, pager: { ...action.payload.pager } };
    case TIME_REPORTS_RESET_PARAMS:
      return { ...state, params: { ...initialState.params } };
    case TIME_REPORTS_RESET_FILTERS:
      return { ...state, filters: [...initialState.filters] };
    case TIME_REPORTS_RESET_SORTING:
      return { ...state, sorting: [...initialState.sorting] };
    case TIME_REPORTS_RESET_PAGER:
      return { ...state, pager: { ...initialState.pager } };
    default:
      return state;
  }
}
