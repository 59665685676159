import React from "react";
import { FormLabel, Grid } from "@material-ui/core";
import { ReactElement } from "react";

interface ErrorMessages {
  errors: Array<string> | Object | null;
}

export default function ErrorMessages({ errors }: ErrorMessages) {
  const errorMessages: ReactElement[] = [];

  if (errors !== null) {
    if (Array.isArray(errors)) {
      for (const error of errors) {
        errorMessages.push(<FormLabel error={true}>{error}</FormLabel>);
      }
    } else {
      for (const [key, error] of Object.entries(errors)) {
        errorMessages.push(
          <FormLabel error={true}>
            {key}: {error}
          </FormLabel>
        );
      }
    }
  }

  return <Grid>{errorMessages}</Grid>;
}
