import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import {
  GroupingState,
  IntegratedGrouping,
  FilteringState,
  IntegratedFiltering,
  PagingState,
  IntegratedPaging,
  SortingState,
  IntegratedSorting
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
  TableGroupRow,
  GroupingPanel,
  Toolbar,
  TableFilterRow,
  PagingPanel
} from "@devexpress/dx-react-grid-material-ui";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import { useIsContractOperationsAllowed } from "../../hooks";
import clsx from "clsx";
import { ROUTER_BASENAME } from "../../variables/general";
import SortButton from "../CustomButtons/SortButton";
import GroupButton from "../CustomButtons/GroupButton";

const tableRowStyles = theme => ({
  tableRow: {
    cursor: "pointer"
  },
  hovered: {
    backgroundColor: theme.palette.primary.main
  },
  hoveredCell: {
    color: "white"
  }
});

const useTableRowStyles = makeStyles(tableRowStyles);

function TableRow({ row, children, ...restProps }) {
  const history = useHistory();
  const classes = useTableRowStyles();
  const [renderRedirect, setRenderRedirect] = useState(false);
  const [hovered, setHovered] = useState(false);
  const canEditContract = useIsContractOperationsAllowed();

  const projectPath = `/project/${row.id}`;

  // Change font color in cells on row hover.
  const newChildren = useMemo(() => {
    if (hovered) {
      return children.map(child =>
        React.cloneElement(child, {
          className: classes.hoveredCell
        })
      );
    } else {
      return children;
    }
  }, [hovered, children, classes.hoveredCell]);

  // Cannot use router Link as wrapper because it breaks all row styles.
  if (renderRedirect) {
    history.push(projectPath);
  }

  return (
    <Table.Row
      className={clsx(
        canEditContract && classes.tableRow,
        hovered && classes.hovered
      )}
      onMouseDown={
        canEditContract
          ? event => {
              if (event.button === 0) {
                setRenderRedirect(true);
              } else if (event.button === 1) {
                window.open(
                  window.location.origin + ROUTER_BASENAME + projectPath,
                  "_blank"
                );
              }
            }
          : null
      }
      onMouseEnter={
        canEditContract
          ? event => {
              setHovered(true);
            }
          : null
      }
      onMouseLeave={
        canEditContract
          ? event => {
              setHovered(false);
            }
          : null
      }
      {...restProps}
      children={newChildren}
    />
  );
}

const GroupCellContent = props =>
  props.row.value === null ? (
    <span>{`Unknown ${props.column.title}`}</span>
  ) : (
    <TableGroupRow.Content {...props} />
  );

function ProjectsGrid(props) {
  const {
    rows,
    columns,
    columnExtensions,
    onSortingChange,
    sorting,
    sortingColumnExtensions,
    filters,
    onFiltersChange,
    filtersColumnExtensions,
    filterCellComponent,
    grouping,
    onGroupingChange,
    groupingColumnExtensions
  } = props;
  return (
    <Paper>
      <Grid rows={rows} columns={columns}>
        <SortingState
          sorting={sorting}
          onSortingChange={onSortingChange}
          columnExtensions={sortingColumnExtensions}
        />
        <IntegratedSorting />
        <GroupingState
          grouping={grouping}
          onGroupingChange={onGroupingChange}
          columnExtensions={groupingColumnExtensions}
        />
        <IntegratedGrouping />
        <FilteringState
          filters={filters}
          onFiltersChange={onFiltersChange}
          columnExtensions={filtersColumnExtensions}
        />
        <IntegratedFiltering />
        <PagingState defaultCurrentPage={0} pageSize={50} />
        <IntegratedPaging />
        <Table rowComponent={TableRow} columnExtensions={columnExtensions} />
        <TableGroupRow contentComponent={GroupCellContent} />
        <TableFilterRow cellComponent={filterCellComponent} />
        <Toolbar />
        <GroupingPanel
          showGroupingControls
          emptyMessageComponent={() =>
            "Click on column group symbol to group by this column"
          }
        />
        <PagingPanel />
        <TableHeaderRow
          showGroupingControls
          showSortingControls
          sortLabelComponent={SortButton}
          groupButtonComponent={GroupButton}
        />
      </Grid>
    </Paper>
  );
}

ProjectsGrid.propTypes = {
  rows: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  columnExtensions: PropTypes.array,
  onSortingChange: PropTypes.func.isRequired,
  sorting: PropTypes.array.isRequired,
  sortingColumnExtensions: PropTypes.array,
  filters: PropTypes.array.isRequired,
  onFiltersChange: PropTypes.func.isRequired,
  filtersColumnExtensions: PropTypes.array,
  filterCellComponent: PropTypes.func,
  grouping: PropTypes.array.isRequired,
  onGroupingChange: PropTypes.func.isRequired,
  groupingColumnExtensions: PropTypes.array
};

export default ProjectsGrid;
