import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Route, Switch, Redirect, BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import { ROUTER_BASENAME } from "./variables/general";

// core components
import Admin from "layouts/Admin";
import Login from "layouts/Login";

import "assets/css/material-dashboard-react.css?v=1.6.0";
import { indigo } from "@material-ui/core/colors";
import { colors } from "@material-ui/core";

const hist = createBrowserHistory();

const accessToken = localStorage.getItem("token");
const theme = createMuiTheme({
  palette: {
    primary: indigo
  },
  snackBar: {
    minWidth: "212px",
    justifyContent: "center"
  },
  snackBarSuccess: {
    backgroundColor: colors.green[600]
  },
  snackBarMessage: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  snackbarIcon: {
    opacity: 0.9,
    marginRight: "5px"
  }
});

theme.snackBarError = {
  backgroundColor: theme.palette.error.dark
};

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <BrowserRouter basename={ROUTER_BASENAME}>
        <Switch>
          <Route
            path="/login"
            component={() => (accessToken ? <Redirect to="/" /> : <Login />)}
          />
          <Route
            component={() =>
              accessToken ? <Admin /> : <Redirect to="/login" />
            }
            history={hist}
          />
        </Switch>
      </BrowserRouter>
    </Provider>
  </ThemeProvider>,
  document.getElementById("root")
);
