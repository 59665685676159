import { User } from "../../types/user";

export const RETRIEVE_CURRENT_USER = "RETRIEVE_CURRENT_USER";
export const SET_CURRENT_USER = "SET_CURRENT_USER";

type RetrieveCurrentUserAction = {
  type: typeof RETRIEVE_CURRENT_USER;
};

type SetCurrentUserAction = {
  type: typeof SET_CURRENT_USER;
  payload: User;
};

export type CurrentUserActionTypes =
  | RetrieveCurrentUserAction
  | SetCurrentUserAction;

export type CurrentUserState = {
  fetching: boolean;
  user: User | null;
};
