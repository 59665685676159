import React, { useEffect } from "react";
import PropTypes from "prop-types";
// @material-ui/core
import {
  Input,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Box,
  CircularProgress,
  Fab,
  Button
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import AddIcon from "@material-ui/icons/Add";
import contractsStyle from "assets/jss/material-dashboard-react/views/contractsStyle.jsx";
import ContractsGrid from "components/Contracts/ContractsGrid.jsx";
import { useIsContractOperationsAllowed } from "hooks";
import { useDispatch, useSelector } from "react-redux";
import {
  contractsRetrieveContracts,
  contractsSetParams,
  contractsSetFilters,
  contractsSetSorting,
  contractsSetGrouping,
  contractsResetParams,
  contractsResetFilters,
  contractsResetSorting,
  contractsResetGrouping,
  retrieveAccountManagers,
  projectsRetrieveProjects
} from "redux/actions";
import { Link } from "react-router-dom";
import {
  CONTRACT_PROVIDERS,
  CONTRACT_STATUSES,
  CONTRACT_TYPES,
  ACCOUNT_MANAGERS,
  DATE_FORMAT
} from "../../variables/general";
import SelectFilterCell from "../../components/Grid/SelectFilterCell";
import { TableFilterRow } from "@devexpress/dx-react-grid-material-ui";
import { Role } from "types/user";
import moment from "moment";

function FilterCell(props) {
  const { column } = props;
  const accountManagers = useSelector(state => state.accountManagers.data);

  switch (column.name) {
    case "type":
      return (
        <SelectFilterCell
          options={[{ id: "", label: "All" }, ...CONTRACT_TYPES]}
          {...props}
        />
      );
    case "status":
      return (
        <SelectFilterCell
          options={[{ id: "", label: "All" }, ...CONTRACT_STATUSES]}
          {...props}
        />
      );
    case "account_manager_id": {
      let options = accountManagers.map(accountManager => ({
        id: accountManager.name,
        label: accountManager.name
      }));
      options = [{ id: "", label: "All" }, ...options];
      return <SelectFilterCell options={options} {...props} />;
    }
    case "provider":
      return (
        <SelectFilterCell
          options={[{ id: "", label: "All" }, ...CONTRACT_PROVIDERS]}
          {...props}
        />
      );
    default:
      return <TableFilterRow.Cell {...props} />;
  }
}

const useStyles = makeStyles(contractsStyle);

function Contracts(props) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const currentUser = useSelector(state => state.currentUser.user);
  const contracts = useSelector(state => state.contracts.list);
  const projects = useSelector(state => state.projects.list);
  const fetchingContracts = useSelector(state => state.contracts.fetching);
  const accountManagers = useSelector(state => state.accountManagers.data);
  const fetchingAccountManagers = useSelector(
    state => state.accountManagers.fetching
  );
  const params = useSelector(state => state.contracts.params);
  const filters = useSelector(state => state.contracts.filters);
  const sorting = useSelector(state => state.contracts.sorting);
  const grouping = useSelector(state => state.contracts.grouping);

  const contractOperationsAllowed = useIsContractOperationsAllowed();

  useEffect(() => {
    dispatch(contractsRetrieveContracts());
    dispatch(projectsRetrieveProjects());
  }, [dispatch]);

  useEffect(() => {
    if (accountManagers.length === 0) {
      dispatch(retrieveAccountManagers());
    }
  }, [accountManagers, dispatch]);

  const columns = [
    {
      name: "name",
      title: "Contract"
    },
    {
      name: "project_id",
      title: "Project",
      getCellValue: row => {
        let value = null;
        if (row.project_id !== null) {
          const project = projects.find(
            project => project.id === row.project_id
          );
          if (project !== undefined) {
            value = project.name;
          }
        }
        return value;
      }
    },
    {
      name: "start_date",
      title: "Start Date",
      getCellValue: row =>
        row.time_intervals && row.time_intervals[0]
          ? moment(row.time_intervals[0].start_date).format(DATE_FORMAT)
          : ""
    },
    {
      name: "client",
      title: "Client"
    },
    {
      name: "developer",
      title: "Developer"
    },
    {
      name: "account_manager_id",
      title: "Account Manager",
      getCellValue: row => {
        let value = null;
        if (row.account_manager_id !== null) {
          const accountManager = accountManagers.find(
            accountManager => accountManager.uid === row.account_manager_id
          );
          if (accountManager !== undefined) {
            value = accountManager.name;
          }
        }
        return value;
      }
    },
    {
      name: "type",
      title: "Type"
    },
    {
      name: "status",
      title: "Status"
    }
  ];

  const showHourlyRate =
    currentUser.roles.includes(Role.HeadOfDepartment) ||
    currentUser.roles.includes(Role.Manager) ||
    currentUser.roles.includes(Role.StaffingManager);

  if (showHourlyRate) {
    columns.push({
      name: "hourly_rate",
      title: "Rate"
    });
  }

  const grid = (
    <ContractsGrid
      rows={contracts}
      columns={columns}
      columnExtensions={[
        { columnName: "name" },
        { columnName: "client", width: "15%" },
        { columnName: "developer", width: "15%" },
        { columnName: "account_manager_id", width: "15%" },
        { columnName: "type", width: "9%" },
        { columnName: "status", width: "9%" },
        { columnName: "hourly_rate", width: "7%" },
        { columnName: "start_date", width: "10%" }
      ]}
      onSortingChange={sorting => {
        dispatch(contractsSetSorting(sorting));
      }}
      sorting={sorting}
      filters={filters}
      onFiltersChange={filters => {
        dispatch(contractsSetFilters(filters));
      }}
      filterCellComponent={FilterCell}
      grouping={grouping}
      onGroupingChange={grouping => {
        dispatch(contractsSetGrouping(grouping));
      }}
    />
  );

  return (
    <div>
      <form className={classes.filtersForm} autoComplete="off">
        <GridContainer>
          <GridItem xs={12} sm={4} md={3}>
            <FormControl className={classes.formControl} fullWidth>
              <InputLabel shrink htmlFor="account-manager-label-placeholder">
                Account manager
              </InputLabel>
              <Select
                value={params.accountManagerId}
                onChange={event => {
                  dispatch(
                    contractsSetParams({
                      ...params,
                      accountManagerId: event.target.value
                    })
                  );
                  dispatch(contractsRetrieveContracts());
                }}
                input={
                  <Input
                    name="account-manager"
                    id="account-manager-label-placeholder"
                  />
                }
                displayEmpty
                name="account-manager"
                className={classes.selectEmpty}
              >
                <MenuItem value={0}>
                  <em>All</em>
                </MenuItem>
                {ACCOUNT_MANAGERS.map(accountManager => (
                  <MenuItem key={accountManager.uid} value={accountManager.uid}>
                    {accountManager.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </GridItem>
          <GridItem container alignItems="center" xs={12} sm={4} md={3}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={event => {
                dispatch(contractsResetParams());
                dispatch(contractsResetFilters());
                dispatch(contractsResetSorting());
                dispatch(contractsResetGrouping());
                dispatch(contractsRetrieveContracts());
                event.preventDefault();
              }}
            >
              Reset filters
            </Button>
          </GridItem>
          {contractOperationsAllowed && (
            <GridItem xs={12} sm={4} md={6}>
              <Link to="/contract/add">
                <Fab color="primary" style={{ float: "right" }}>
                  <AddIcon />
                </Fab>
              </Link>
            </GridItem>
          )}
        </GridContainer>
      </form>
      <Box style={{ display: "flex", justifyContent: "center" }}>
        {fetchingContracts || fetchingAccountManagers ? (
          <CircularProgress className={classes.progress} />
        ) : (
          grid
        )}
      </Box>
    </div>
  );
}

Contracts.propTypes = {
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default Contracts;
