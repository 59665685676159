import React, { useEffect, useMemo } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  contractFormSetContract,
  configRetrieveContractCurrencies
} from "redux/actions";
import { ContractFormContract } from "../../types/contract";
import { ContractFormErrors } from "../../redux/types/contractForm";

interface CurrencyDropDownProps {
  contract: ContractFormContract;
  isBeingEdited: boolean;
  fieldsErrors: ContractFormErrors;
}

export function CurrencyDropDown({
  contract,
  isBeingEdited,
  fieldsErrors
}: CurrencyDropDownProps) {
  const dispatch = useDispatch();

  // @ts-ignore
  const currencies = useSelector(state => state.config.contractCurrencies);

  useEffect(() => {
    if (Object.keys(currencies).length === 0) {
      dispatch(configRetrieveContractCurrencies());
    }
  }, [currencies, dispatch]);

  const currenciesOptions = useMemo(() => {
    const options = [];
    for (const currencyCode of Object.keys(currencies)) {
      options.push({
        value: currencyCode,
        label: `${currencies[currencyCode]["name"]} ${currencies[currencyCode]["symbol"]}`
      });
    }
    return options;
  }, [currencies]);

  return (
    <FormControl
      disabled={!isBeingEdited}
      fullWidth
      component={"div"}
      error={fieldsErrors.currency_code !== undefined}
      required
    >
      <InputLabel htmlFor="currency_code">Currency</InputLabel>
      <Select
        value={contract.currency_code}
        onChange={event => {
          dispatch(
            contractFormSetContract({
              ...contract,
              currency_code: event.target.value as string
            })
          );
        }}
        inputProps={{
          name: "currency_code",
          id: "currency_code",
          fullWidth: true
        }}
      >
        {currenciesOptions.map((option, index) => (
          <MenuItem key={index} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{fieldsErrors.currency_code || ""}</FormHelperText>
    </FormControl>
  );
}
