import {
  GENERAL_RETRIEVE_CLIENT_NAMES,
  GENERAL_SET_CLIENT_NAMES,
  generalActionTypes
} from "../types/general";

export function generalRetrieveClientNames(): generalActionTypes {
  return {
    type: GENERAL_RETRIEVE_CLIENT_NAMES
  };
}

export function generalSetClientNames(
  clientNames: string[]
): generalActionTypes {
  return {
    type: GENERAL_SET_CLIENT_NAMES,
    payload: clientNames
  };
}
