import React, { useState } from "react";
import {
  CssBaseline,
  FormControl,
  Input,
  InputLabel,
  Paper,
  Typography,
  FormLabel,
  Button,
  Avatar
} from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import dataFetcher from "services/dataFetcher";
import loginStyles from "assets/jss/material-dashboard-react/layouts/loginStyle";
import { makeStyles } from "@material-ui/styles";
import { RouteComponentProps } from "react-router-dom";

// @ts-ignore
const useStyles = makeStyles(loginStyles);

type LoginFormState = {
  name: string;
  pass: string;
};

type ErrorState = string;

const LoginForm: React.FunctionComponent<RouteComponentProps<{}>> = function(
  props: RouteComponentProps<{}>
) {
  const classes = useStyles();

  const [formState, setFormState] = useState<LoginFormState>({
    name: "",
    pass: ""
  });

  const [errorState, setErrorState] = useState<ErrorState>("");

  function sendRequest() {
    if (formState.name.length > 0 && formState.pass.length > 0) {
      dataFetcher.user
        .login(formState)
        .then(result => {
          let errorMessage: string | null = null;
          if (result.data.status === "success") {
            localStorage.setItem("token", result.data.data);
            localStorage.setItem("modal-was-shown", "false");
            window.location.reload();
          } else if (result.data.status === "error") {
            errorMessage = "Unknown login error.";
            if (
              Array.isArray(result.data.data) &&
              result.data.data.length !== 0
            ) {
              errorMessage = result.data.data.pop() as string;
            } else if (
              typeof result.data.data === "object" &&
              result.data.data !== null
            ) {
              const errorKeys = Object.keys(result.data.data);
              if (errorKeys.length !== 0) {
                errorMessage = result.data.data[errorKeys[0]];
              }
            }
          } else {
            errorMessage = "Login error. Unrecognized response from server.";
          }
          if (errorMessage !== null) {
            setErrorState(errorMessage);
          }
        })
        .catch(error => {
          let errorMessage = error.message;
          if (error.response) {
            errorMessage = error.response.data.msg;
          } else if (error.request) {
            errorMessage = "Login attempt failed. No response from server.";
          }
          setErrorState(errorMessage);
        });
    } else {
      setErrorState("Username and password are required params");
    }
  }

  return (
    <main className={classes.main}>
      <CssBaseline />
      <Paper className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Login using your CRM account
        </Typography>
        <form className={classes.form}>
          {!errorState.length || (
            <FormLabel error={true}>{errorState}</FormLabel>
          )}
          <FormControl
            error={!!errorState.length}
            margin="normal"
            required
            fullWidth
          >
            <InputLabel htmlFor="email">Username</InputLabel>
            <Input
              value={formState.name}
              onChange={event => {
                setFormState({ ...formState, name: event.target.value });
              }}
              id="name"
              name="name"
              autoComplete="name"
              autoFocus
            />
          </FormControl>
          <FormControl
            error={!!errorState.length}
            margin="normal"
            required
            fullWidth
          >
            <InputLabel htmlFor="password">Password</InputLabel>
            <Input
              value={formState.pass}
              onChange={event => {
                setFormState({ ...formState, pass: event.target.value });
              }}
              name="password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
          </FormControl>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={event => {
              sendRequest();
              event.preventDefault();
            }}
          >
            Sign in
          </Button>
        </form>
      </Paper>
    </main>
  );
};
export default LoginForm;
