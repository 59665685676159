import React from "react";
import PublishIcon from "@material-ui/icons/Publish";
import { Button } from "@material-ui/core";

function GroupButton({ disabled, onGroup }) {
  return (
    <Button
      className="groupButton"
      variant="text"
      disabled={disabled}
      onClick={onGroup}
    >
      <PublishIcon style={{ height: "18px" }} />
    </Button>
  );
}

export default GroupButton;
